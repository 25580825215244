import * as z from 'zod';
import { apiFilterTypeValues } from '@api/@types';
export const tFilterSchema = z.object({
    products: z
        .array(z.string())
        .nullable()
        .optional()
        .transform(it => it || undefined),
    builds: z
        .array(z.string())
        .nullable()
        .optional()
        .transform(it => it || undefined),
    type: z.enum(apiFilterTypeValues),
    os: z
        .array(z.string())
        .nullable()
        .optional()
        .transform(it => it || undefined),
    arch: z
        .array(z.string())
        .nullable()
        .optional()
        .transform(it => it || undefined),
    quality: z
        .object({
        name: z.string(),
        orderValue: z.number(),
        condition: z.string()
    })
        .nullable()
        .optional()
        .transform(it => it || undefined)
});
