import { jsx as _jsx } from "react/jsx-runtime";
import { useController, useFormContext } from 'react-hook-form';
import { ToolboxSettingField } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/toolbox-setting-field';
import { assignTestId } from '@common/infra/tests/assign-test-id';
const DEFAULT_SHOW_JETBRAINS_ACCOUNT_TOOLBOX_SETTING = {
    value: true
};
export function ShowJetBrainsAccountSettingField() {
    var _a;
    const { control } = useFormContext();
    const { field: { value: showJetBrainsAccountLogin, onChange: onShowJetBrainsAccountLoginChange } } = useController({
        control,
        name: 'content.showJetBrainsAccountLogin'
    });
    return (_jsx(ToolboxSettingField, { label: "Show JetBrains Account login", value: (_a = showJetBrainsAccountLogin === null || showJetBrainsAccountLogin === void 0 ? void 0 : showJetBrainsAccountLogin.value) !== null && _a !== void 0 ? _a : DEFAULT_SHOW_JETBRAINS_ACCOUNT_TOOLBOX_SETTING.value, onChange: newValue => {
            onShowJetBrainsAccountLoginChange({
                value: newValue
            });
        }, ...assignTestId('show-jetbrains-account-toolbox-setting-field') }));
}
