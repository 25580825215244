import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Navigate, useParams } from 'react-router-dom';
import { getApplications } from '@app/common/store/profiles/selectors';
import { findMainApplicationsRule } from '@app/common/store/profiles/profile-reducer-helpers';
import { ToolsFilter } from '@modules/applications/tools-filter';
import { Dialog } from '@common/dialog/dialog';
import { useEvaluatedAppFilters } from '@common/queries/profile-evaluations';
import { tFiltersFormSchema } from '../schemas/filters-schema';
import styles from './application-filters-dialog.css';
function ApplicationFiltersDialog({ onReject, onSubmit }) {
    var _a, _b, _c, _d;
    const { id: profileId } = useParams();
    const { rules } = useSelector(getApplications);
    const form = useForm({
        defaultValues: { filters: (_b = (_a = findMainApplicationsRule(rules)) === null || _a === void 0 ? void 0 : _a.filters) !== null && _b !== void 0 ? _b : [] },
        resolver: zodResolver(tFiltersFormSchema)
    });
    const { formState: { isDirty, isValid, isSubmitting, errors }, handleSubmit, watch, setValue } = form;
    const watchFilters = watch('filters');
    const setFilters = (filters) => {
        setValue('filters', filters, {
            shouldDirty: true,
            shouldValidate: true
        });
    };
    const { isFetching: isFilterToolsLoading, data: filterTools } = useEvaluatedAppFilters(watchFilters);
    const submit = async (formData) => {
        onSubmit(formData.filters);
    };
    if (!profileId) {
        return _jsx(Navigate, { to: "/profiles" });
    }
    return (_jsxs(Dialog, { autoFocusFirst: false, show: true, onCloseAttempt: onReject, size: "wide", children: [_jsx(Header, { children: "Configure Available Applications" }), _jsxs(Content, { children: [_jsx(ToolsFilter, { filters: watchFilters, onFiltersChange: setFilters, target: "applications", profileId: profileId, isFilterToolsLoading: isFilterToolsLoading, filterTools: filterTools !== null && filterTools !== void 0 ? filterTools : [] }), ((_c = errors.filters) === null || _c === void 0 ? void 0 : _c.message) && (_jsx("div", { className: styles.errorMessage, children: ((_d = errors.filters) === null || _d === void 0 ? void 0 : _d.message) && _jsx("p", { children: errors.filters.message }) }))] }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(submit), type: "submit", loader: isSubmitting, disabled: !isDirty || !isValid, children: "Save" }), _jsx(Button, { onClick: onReject, children: "Close" })] })] }));
}
export default ApplicationFiltersDialog;
