import { isNil } from 'lodash';
import * as z from 'zod';
import { apiPluginSourceValues } from '@api/@types';
import { tSettingOptionSchema } from './setting-option-schema';
export const tSingleAutoInstalledPluginSchema = (isRestricted, restrictedPlugins) => {
    return z
        .object({
        type: z.union([z.literal('channel'), z.literal('version')]),
        pluginId: isRestricted
            ? z
                .string()
                .min(1, 'Please set plugin ID')
                .refine(val => restrictedPlugins === null || restrictedPlugins === void 0 ? void 0 : restrictedPlugins.includes(val), {
                message: 'This plugin is not allowed in Restricted Mode'
            })
            : z.string().min(1, 'Please set plugin ID'),
        description: z
            .string()
            .transform(x => x !== null && x !== void 0 ? x : '')
            .optional(),
        forced: z.boolean(),
        source: z.enum(apiPluginSourceValues),
        version: z
            .string()
            .optional()
            .nullable()
            .transform(x => x || undefined),
        channel: z.union([
            z
                .object({
                id: z.string().optional().nullable(),
                name: z.string().optional().nullable()
            })
                .optional()
                .transform(x => { var _a; return (isNil(x === null || x === void 0 ? void 0 : x.id) || ((_a = x === null || x === void 0 ? void 0 : x.name) === null || _a === void 0 ? void 0 : _a.trim().length) === 0 ? undefined : x); }),
            z.null()
        ])
    })
        .refine(data => { var _a; return data.type !== 'version' || (((_a = data.version) === null || _a === void 0 ? void 0 : _a.length) || 0) > 0; }, {
        message: 'Version expected',
        path: ['version']
    });
};
export const tAutoInstalledPluginsSchema = (isRestricted, restrictedPlugins) => tSettingOptionSchema.extend({
    content: z
        .array(tSingleAutoInstalledPluginSchema(isRestricted, restrictedPlugins))
        .min(1, { message: 'Please add Plugin' })
});
