import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import InlineLoader from '@jetbrains/ring-ui/components/loader-inline/loader-inline';
import { Collapsible } from '@common/collapsible/collapsible';
import { formatPlural } from '@common/format-plural';
import ProductLogo from '@common/product-logo/product-logo';
import styles from './allowed-tools.css';
export function AllowedTools(props) {
    const { className, isFilterToolsLoading, target, filterTools } = props;
    if (isFilterToolsLoading) {
        return (_jsx("div", { className: classNames(styles.wrapper, className), children: _jsx(InlineLoader, { className: className }) }));
    }
    return (_jsx("div", { className: classNames(styles.wrapper, className), children: _jsx(Collapsible, { titleClassName: styles.title, title: formatTitle(target, filterTools !== null && filterTools !== void 0 ? filterTools : []), children: _jsx("div", { className: styles.toolsList, children: filterTools === null || filterTools === void 0 ? void 0 : filterTools.map(tool => (_jsxs("div", { className: styles.tool, children: [_jsx(ProductLogo, { product: tool.product, className: styles.toolIcon }), _jsx("span", { className: styles.toolName, children: tool.productName })] }, tool.product))) }) }) }));
}
function formatTitle(target, filterTools) {
    switch (target) {
        case 'applications':
            return formatPlural(filterTools.length, {
                one: '1 Application will be allowed',
                multiple: `${filterTools.length} Applications will be allowed`,
                zero: 'No Applications will be allowed'
            });
        case 'restrictions':
            return formatPlural(filterTools.length, {
                one: '1 Tool will be restricted',
                multiple: `${filterTools.length} Tools will be restricted`,
                zero: 'No Tools will be restricted'
            });
        default:
            return formatPlural(filterTools.length, {
                one: '1 Tool will be affected',
                multiple: `${filterTools.length} Tools will be affected`,
                zero: 'No Tools will be affected'
            });
    }
}
