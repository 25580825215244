import { jsx as _jsx } from "react/jsx-runtime";
import { useController, useFormContext } from 'react-hook-form';
import { ToolboxSettingField } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/toolbox-setting-field';
import { assignTestId } from '@common/infra/tests/assign-test-id';
const DEFAULT_SHOW_SERVICES_TAB_TOOLBOX_SETTING = {
    value: true
};
export function ShowServicesTabSettingField() {
    var _a;
    const { control } = useFormContext();
    const { field: { value: showServicesTab, onChange: onShowServicesTabChange } } = useController({
        control,
        name: 'content.showServicesTab'
    });
    return (_jsx(ToolboxSettingField, { label: "Show Services tab", value: (_a = showServicesTab === null || showServicesTab === void 0 ? void 0 : showServicesTab.value) !== null && _a !== void 0 ? _a : DEFAULT_SHOW_SERVICES_TAB_TOOLBOX_SETTING.value, onChange: newValue => {
            onShowServicesTabChange({
                value: newValue
            });
        }, ...assignTestId('show-services-tab-toolbox-setting-field') }));
}
