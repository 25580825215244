// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__b25 {
  max-height: calc(var(--ring-unit) * 50);
  overflow-y: auto;
  border-radius: var(--ring-border-radius);
  border: 1px solid var(--ring-line-color);
  padding: calc(var(--ring-unit) * 2);

  .title__b68:not(:hover) {
    color: var(--ring-text-color);
  }

  .emptyPlaceholder__ba0 {
    margin-left: calc(var(--ring-unit) * 1.5);
  }

  .toolsList__ca3 {
    display: flex;
    flex-direction: column;
    padding-left: calc(var(--ring-unit) * 1.5);
  }

  .tool__ce6 {
    margin-top: calc(var(--ring-unit) * 1.5);
  }

  .tool__ce6 {
    display: flex;
    align-items: center;

    .toolIcon__f2c {
      height: calc(var(--ring-unit) * 3);
    }

    .toolIcon__f2c + .toolName__fc0 {
      margin-left: var(--ring-unit);
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/applications/tools-filter/allowed-tools/allowed-tools.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,gBAAgB;EAChB,wCAAwC;EACxC,wCAAwC;EACxC,mCAAmC;;EAEnC;IACE,6BAA6B;EAC/B;;EAEA;IACE,yCAAyC;EAC3C;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,0CAA0C;EAC5C;;EAEA;IACE,wCAAwC;EAC1C;;EAEA;IACE,aAAa;IACb,mBAAmB;;IAEnB;MACE,kCAAkC;IACpC;;IAEA;MACE,6BAA6B;IAC/B;EACF;AACF","sourcesContent":[".wrapper {\n  max-height: calc(var(--ring-unit) * 50);\n  overflow-y: auto;\n  border-radius: var(--ring-border-radius);\n  border: 1px solid var(--ring-line-color);\n  padding: calc(var(--ring-unit) * 2);\n\n  .title:not(:hover) {\n    color: var(--ring-text-color);\n  }\n\n  .emptyPlaceholder {\n    margin-left: calc(var(--ring-unit) * 1.5);\n  }\n\n  .toolsList {\n    display: flex;\n    flex-direction: column;\n    padding-left: calc(var(--ring-unit) * 1.5);\n  }\n\n  .tool {\n    margin-top: calc(var(--ring-unit) * 1.5);\n  }\n\n  .tool {\n    display: flex;\n    align-items: center;\n\n    .toolIcon {\n      height: calc(var(--ring-unit) * 3);\n    }\n\n    .toolIcon + .toolName {\n      margin-left: var(--ring-unit);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__b25`,
	"title": `title__b68`,
	"emptyPlaceholder": `emptyPlaceholder__ba0`,
	"toolsList": `toolsList__ca3`,
	"tool": `tool__ce6`,
	"toolIcon": `toolIcon__f2c`,
	"toolName": `toolName__fc0`
};
export default ___CSS_LOADER_EXPORT___;
