import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CodeWithMeLogo } from '@jetbrains/logos/react';
import Button from '@jetbrains/ring-ui/components/button/button';
import { ConstituentBlock } from '@modules/constituents/constituent-block';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './code-with-me-disabled.css';
export function CodeWithMeDisabled(props) {
    const { className, onEnabledClick, trialData } = props;
    return (_jsxs(ConstituentBlock, { title: "Code With Me", titleLogo: _jsx(CodeWithMeLogo, {}), state: "disabled", className: className, trialData: trialData, ...assignTestId('cwm-block'), children: [_jsx("p", { children: "Code With Me Enterprise offers real-time synchronous collaboration for developers within your organization" }), _jsx(Button, { onClick: onEnabledClick, className: styles.button, "data-test": "enable-button", children: "Enable..." })] }));
}
