import { uniq } from 'lodash';
export function getUpdatedBuilds(key, builds, dataListOptions) {
    if (!builds) {
        return [key];
    }
    const wasPreviouslyImplicitlyChecked = builds.some(it => it === key);
    const implicitlySelectedParents = builds.filter(it => isChildOf(key, it));
    if (!implicitlySelectedParents.length) {
        if (wasPreviouslyImplicitlyChecked) {
            return builds.filter(it => it !== key);
        }
        return builds.filter(it => !isChildOf(it, key)).concat([key]);
    }
    return builds
        .filter(it => implicitlySelectedParents.some(selectedParent => it !== selectedParent))
        .concat(uniq(implicitlySelectedParents.flatMap(getChildrenOfThisParent)))
        .filter(it => it !== key && !isChildOf(key, it));
    function getChildrenOfThisParent(parentKey) {
        const parentOption = dataListOptions.filter(it => it.build === parentKey)[0];
        return ((parentOption === null || parentOption === void 0 ? void 0 : parentOption.items) || []).map(({ build }) => build);
    }
    function isChildOf(childKey, parentKey) {
        const potentialParentWithoutAsterisk = parentKey.split('*')[0];
        return parentKey !== childKey && childKey.startsWith(potentialParentWithoutAsterisk);
    }
}
