import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@jetbrains/ring-ui/components/button/button';
import cn from 'classnames';
import ButtonSet from '@jetbrains/ring-ui/components/button-set/button-set';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import confirmResetForm from '@modules/ai/common/dialogs/confirm-reset-form/confirm-reset-form';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './ai-form-actions.css';
export function AiFormActions(props) {
    const { form, className, onFormSave } = props;
    const { reset, formState, handleSubmit } = form;
    const handleCancel = () => {
        confirmResetForm()
            .then(() => reset())
            .catch(hideConfirm);
    };
    const canSubmit = formState.isDirty && formState.isValid;
    return (_jsx("div", { className: cn(styles.wrapper, className), children: _jsxs(ButtonSet, { children: [_jsx(Button, { primary: true, disabled: !canSubmit, onClick: handleSubmit(onFormSave), ...assignTestId('save-ai-settings'), children: "Save" }), _jsx(Button, { onClick: handleCancel, disabled: !(formState === null || formState === void 0 ? void 0 : formState.isDirty), children: "Cancel" })] }) }));
}
