// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formContainer__d97 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--ring-unit) * 1.5);
}

.errorMessage__aa4 {
  margin-top: var(--ring-unit);
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/notifications/notification-dialog-form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iCAAiC;AACnC;;AAEA;EACE,4BAA4B;EAC5B,8BAA8B;AAChC","sourcesContent":[".formContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.errorMessage {\n  margin-top: var(--ring-unit);\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `formContainer__d97`,
	"errorMessage": `errorMessage__aa4`
};
export default ___CSS_LOADER_EXPORT___;
