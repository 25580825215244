import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Text from '@jetbrains/ring-ui/components/text/text';
import classNames from 'classnames';
import { AI_PROVIDERS } from '@modules/ai/common/constants';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './ai-available-providers.css';
export function AiAvailableProviders(props) {
    const { availableProviders, className } = props;
    return (_jsxs("div", { className: classNames(styles.availableProvidersContainer, className), ...assignTestId('ai-available-providers'), children: [_jsx(Text, { size: "s", info: true, children: "Available providers" }), availableProviders === null || availableProviders === void 0 ? void 0 : availableProviders.map(item => {
                var _a;
                return (_jsx(Text, { ...assignTestId('ai-available-provider-type'), children: (_a = AI_PROVIDERS[item]) === null || _a === void 0 ? void 0 : _a.label }, `provider-${item}`));
            })] }));
}
