import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from '@jetbrains/ring-ui/components/select/select';
import Toggle from '@jetbrains/ring-ui/components/toggle/toggle';
import checkmarkIcon from '@jetbrains/icons/checkmark';
import Text from '@jetbrains/ring-ui/components/text/text';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './toolbox-setting-field.css';
const FORCED_OPTIONS = [
    {
        key: 'default',
        label: 'Not specified',
        selectedLabel: 'Not specified',
        forced: null
    },
    {
        key: 'not-forced',
        label: 'User can edit setting',
        selectedLabel: 'Editable',
        forced: false
    },
    {
        key: 'forced',
        label: 'User can only view setting',
        selectedLabel: 'Read only',
        forced: true
    }
];
export function ToolboxSettingField({ label, value, forced, onChange, onForcedChange, ...rest }) {
    var _a;
    const selectedSettingOption = (_a = FORCED_OPTIONS.find(option => forced === option.forced)) !== null && _a !== void 0 ? _a : FORCED_OPTIONS[0];
    const settingOptions = FORCED_OPTIONS.map(option => option.key === selectedSettingOption.key
        ? {
            ...option,
            rightGlyph: checkmarkIcon
        }
        : option);
    return (_jsxs("div", { className: styles.settingControls, ...assignTestId('toolbox-setting-field', rest), children: [_jsx("div", { className: styles.toggle, children: forced === null ? (_jsx(Text, { children: label })) : (_jsx(Toggle, { checked: value, onChange: e => onChange === null || onChange === void 0 ? void 0 : onChange(e.target.checked), children: label })) }), onForcedChange && (_jsx(Select, { minWidth: 270, className: styles.grayAnchor, type: Select.Type.INLINE, selectedLabel: selectedSettingOption.selectedLabel, onSelect: newSettingOption => {
                    if (!newSettingOption) {
                        return;
                    }
                    onForcedChange(newSettingOption.forced);
                }, selected: selectedSettingOption, data: settingOptions }))] }));
}
