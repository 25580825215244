import { apiClientManager } from '@common/api';
export async function getConstituentsList() {
    const response = await apiClientManager.getClient().get('/api/constituents', {});
    return response.data.items;
}
export async function requestConstituentTrial(constituentId) {
    const response = await apiClientManager
        .getClient()
        .post(`/api/license/constituents/trial`, {
        constituents: [constituentId]
    });
    return response.data;
}
