import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import userWarningIcon from '@jetbrains/icons/user-warning';
import { Size } from '@jetbrains/ring-ui/components/icon/icon__constants';
import { useNavigate } from 'react-router-dom';
import { isNil } from 'lodash';
import { objectToSearchString } from 'serialize-query-params';
import { ConstituentBlock } from '@modules/constituents/constituent-block';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { AiLogo } from '../../../common/ai-logo/ai-logo';
import styles from './ai-enabled.css';
export function AiEnabled(props) {
    const { className, aiPlatformData, license, licenseType, onDisableClick } = props;
    const { activeUsersCount, assignedUsersLimit, disabledByLimitsUsersCount } = aiPlatformData;
    const navigate = useNavigate();
    const usersDataDisplayed = activeUsersCount > 0 || assignedUsersLimit !== undefined;
    const overLimitDisplayed = !isNil(assignedUsersLimit) && disabledByLimitsUsersCount > 0;
    const handleShowUsersClick = useCallback(() => navigate({
        pathname: '/users',
        search: objectToSearchString({ constituentId: 'ai' })
    }), [navigate]);
    const handleGoToProfilesClick = useCallback(() => navigate('/profiles'), [navigate]);
    const handleGoToSettings = useCallback(() => navigate('ai-settings'), [navigate]);
    return (_jsxs(ConstituentBlock, { className: className, title: "AI Enterprise", state: "enabled", titleLogo: _jsx(AiLogo, {}), notConfigured: activeUsersCount === 0, trialData: { licenseType, license }, ...assignTestId('ai-block'), children: [_jsx("p", { children: "AI Enterprise offers AI-powered features within JetBrains IDEs for developers in your organization" }), usersDataDisplayed && (_jsxs("p", { className: styles.usersData, children: [!isNil(assignedUsersLimit) && (_jsxs("span", { className: styles.usersLimit, children: ["User Limit: ", assignedUsersLimit] })), activeUsersCount > 0 && (_jsxs("span", { className: styles.usedBy, children: ["Current users: ", activeUsersCount] }))] })), activeUsersCount === 0 && (_jsx("p", { className: styles.notInUseWarning, children: "Enable AI Enterprise in relevant profiles to provide users with access to the product functionality" })), overLimitDisplayed && (_jsxs("p", { className: styles.overLimitError, children: [_jsx(Icon, { className: styles.overLimitIcon, glyph: userWarningIcon, size: Size.Size16, suppressSizeWarning: true }), _jsxs("span", { className: styles.overLimitErrorMsg, children: [disabledByLimitsUsersCount, " users lack access rights"] })] })), _jsxs("div", { className: styles.actions, children: [activeUsersCount ? (_jsx(Button, { onClick: handleShowUsersClick, children: "Go to Users" })) : (_jsx(Button, { onClick: handleGoToProfilesClick, primary: true, children: "Go to Profiles" })), _jsx(Button, { onClick: handleGoToSettings, "data-test": "settings-button", children: "Settings" }), _jsx(Button, { onClick: onDisableClick, "data-test": "disable-button", children: "Disable..." })] })] }));
}
