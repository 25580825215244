import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@jetbrains/ring-ui/components/button/button';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Text from '@jetbrains/ring-ui/components/text/text';
import Icon from '@jetbrains/ring-ui/components/icon/icon';
import warning from '@jetbrains/icons/warning';
import { zodResolver } from '@hookform/resolvers/zod';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDialogCancelConfirmation } from '@app/common/hooks/use-dialog-cancel-confirmation';
import { ExternalLinks } from '@app/common/constants/constants';
import NavigationLink from '@app/common/navigation/navigation-link';
import { ToolsFilter } from '@modules/applications/tools-filter';
import { Dialog } from '@common/dialog/dialog';
import { getApplications } from '@common/store/profiles/selectors';
import { useEvaluatedSettingFilters } from '@common/queries/profile-evaluations';
import { tVmOptionsSchema } from '../../schemas/vm-options-schema';
import { getEmptyVmOption } from '../../schemas/ctors';
import { VmOptionsFieldArray } from './vm-options-field-array';
import styles from './profiles-vm-options-form.css';
export function VmOptionsDialogForm({ item, onReject, onSubmit }) {
    var _a, _b;
    const { id: profileId } = useParams();
    const form = useForm({
        defaultValues: item !== null && item !== void 0 ? item : getEmptyVmOption(),
        mode: 'all',
        delayError: 1000,
        resolver: zodResolver(tVmOptionsSchema)
    });
    const { formState: { isDirty, isSubmitting, errors }, handleSubmit, setValue, watch } = form;
    const setFilters = (filters) => {
        setValue('filters', filters, {
            shouldDirty: true,
            shouldValidate: true
        });
    };
    const watchFilters = watch('filters');
    const applications = useSelector(getApplications);
    const { isFetching: isFilterToolsLoading, data: filterTools } = useEvaluatedSettingFilters(applications.rules, watchFilters);
    const canSubmit = isDirty && !isFilterToolsLoading;
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(onReject, isDirty);
    const submit = (formData) => {
        onSubmit({ ...formData, tools: filterTools !== null && filterTools !== void 0 ? filterTools : [] });
    };
    if (!profileId) {
        return _jsx(Navigate, { to: "/profiles" });
    }
    return (_jsxs(Dialog, { show: true, autoFocusFirst: false, onCloseAttempt: onCloseAttempt, size: "wide", children: [_jsx(Header, { children: "Configure Virtual Machine Options" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(submit), className: styles.formContainer, children: [_jsxs(Text, { className: styles.warningText, children: [_jsx(Icon, { glyph: warning, className: styles.warningIcon }), "Configure VM options carefully and test them in an IDE first. Incorrect configuration may influence IDE performance or even prevent it from starting."] }), _jsx(ToolsFilter, { filters: watchFilters, onFiltersChange: setFilters, target: "settings", profileId: profileId, isFilterToolsLoading: isFilterToolsLoading, filterTools: filterTools !== null && filterTools !== void 0 ? filterTools : [] }), _jsx(VmOptionsFieldArray, {}), _jsxs(Text, { info: true, className: styles.infoText, children: ["JetBrains IDE Services adds the provided VM options to the", ' ', _jsx(NavigationLink, { href: ExternalLinks.JB_CONFIGURE_JVM, target: "_blank", children: "vmoptions" }), ' ', "file"] }), ((_a = errors.filters) === null || _a === void 0 ? void 0 : _a.message) && (_jsx("div", { className: styles.errorMessage, children: ((_b = errors.filters) === null || _b === void 0 ? void 0 : _b.message) && _jsx("p", { children: errors.filters.message }) }))] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(submit), type: "submit", loader: isSubmitting, disabled: !canSubmit, children: "Save" }), _jsx(Button, { ref: cancelButtonRef, onClick: onReject, children: "Cancel" })] })] }));
}
