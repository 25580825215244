// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title__af6.title__af6 {
  margin-bottom: 0;
}

.formWrapper__d0b {
  margin-top: calc(var(--ring-unit) * 3);
  display: flex;
  align-items: flex-start;
}

.availableProviders__c02 {
  margin-left: calc(var(--ring-unit) * 5);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/ai-settings/ai-settings-page.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;EACtC,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".title.title {\n  margin-bottom: 0;\n}\n\n.formWrapper {\n  margin-top: calc(var(--ring-unit) * 3);\n  display: flex;\n  align-items: flex-start;\n}\n\n.availableProviders {\n  margin-left: calc(var(--ring-unit) * 5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `title__af6`,
	"formWrapper": `formWrapper__d0b`,
	"availableProviders": `availableProviders__c02`
};
export default ___CSS_LOADER_EXPORT___;
