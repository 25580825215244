import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import { UserNameField } from '@modules/users/user-details/user-edit-form/form-fields/user-name-field';
import { UserEmailField } from '@modules/users/user-details/user-edit-form/form-fields/user-email-field';
import { UserRoleField } from '@modules/users/user-details/user-edit-form/form-fields/user-role-field';
import { UserOAuthSubjectField } from '@modules/users/user-details/user-edit-form/form-fields/user-oauth-subject-field';
import { tUserSchema } from '@modules/users/user-details/user-edit-form/schemas/user-schema';
import { UserMachineInfoList } from '@modules/users/user-details/user-machine-info-list/user-machine-info-list';
import { ProfilesList } from '@modules/users/user-details/user-edit-form/form-fields/profiles-list/profiles-list';
import { ConstituentsList } from '@modules/constituents/constituents-list/constituents-list';
import { toUserFormValues, toUserPatch } from '@modules/users/user-details/user-edit-form/utils';
import { AddUsersIDPBanner } from '@modules/users/banners/add-users-idp-banner';
import auth from '@common/auth/auth';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { RouteLeavingGuard } from '@common/route-leaving-guard/route-leaving-guard';
import { usePatchUserMutation } from '@common/queries/users';
import { UserChangeSettingsBanner } from './user-change-settings-banner';
import styles from './user-details-edit-page.css';
export function UserDetailsEditPage() {
    const user = useRouteLoaderData('user-details');
    const userRevalidator = useRevalidator();
    const form = useForm({
        defaultValues: toUserFormValues(user),
        resolver: zodResolver(tUserSchema)
    });
    const { handleSubmit, reset, formState } = form;
    const { isDirty, isSubmitting } = formState;
    const areAnalyticsEnabled = auth.getFeatures().analyticsEnabled === true;
    const userPersonalInformationIsDisabled = !user.isActive || user.isReadOnly;
    const enabledConstituentIds = useMemo(() => {
        return user.constituents.reduce((acc, constituent) => {
            if (constituent.state === 'CONFIGURED') {
                acc.push(constituent.constituentId);
            }
            return acc;
        }, []);
        // fixme: there is should be user in dependency array, add it and remove eslint-disable-next-line  when IDES-5338 will be fixed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const mutation = usePatchUserMutation();
    const updateUser = useCallback(async (values) => {
        await mutation.mutateAsync({
            ...toUserPatch(user, values),
            id: user.id
        });
        reset(values);
        userRevalidator.revalidate();
    }, [user, mutation, reset, userRevalidator]);
    const handleDiscard = useCallback(() => reset(user), [user, reset]);
    useEffect(() => {
        form.reset(user);
    }, [form, user]);
    useEffect(() => {
        if (formState.isDirty && !user.isActive) {
            alert.message('Changes in user form were reset due to user deactivation');
        }
    }, [formState.isDirty, user]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.wrapper, children: [user.isReadOnly && user.isActive && (_jsx(AddUsersIDPBanner, { bannerKey: "idp-info-user-form", children: "The user's personal information is managed on a side of the identity provider." })), isDirty && (_jsx(UserChangeSettingsBanner, { className: styles.banner, userName: user.username, isSubmitting: isSubmitting, onSubmit: handleSubmit(updateUser), onDiscard: handleDiscard })), _jsx("div", { className: styles.formWrapper, children: _jsxs(FormProvider, { ...form, children: [_jsxs("div", { className: styles.formData, children: [_jsxs("div", { className: styles.formFields, ...assignTestId('user-form-fields'), children: [_jsx(UserNameField, { disabled: userPersonalInformationIsDisabled }), _jsx(UserEmailField, { disabled: userPersonalInformationIsDisabled }), _jsx(UserRoleField, { disabled: userPersonalInformationIsDisabled }), _jsx(UserOAuthSubjectField, { disabled: true })] }), areAnalyticsEnabled && _jsx(UserMachineInfoList, { machineInfo: user.machineInfo }), _jsx(ProfilesList, { disabled: !user.isActive })] }), _jsxs("div", { className: styles.constituents, ...assignTestId('user-constituents'), children: [_jsx("div", { className: styles.constituentsTitle, children: "IDE Services" }), !!enabledConstituentIds.length && (_jsx(ConstituentsList, { className: styles.constituentList, constituents: enabledConstituentIds })), !enabledConstituentIds.length && (_jsx("p", { className: styles.emptyConstituents, ...assignTestId('constituents-disabled-message'), children: "No IDE services enabled" }))] })] }) })] }), _jsx(RouteLeavingGuard, { when: isDirty, allowedRoutesPatterns: ['/users/:id'] })] }));
}
