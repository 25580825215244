import * as z from 'zod';
import { tSettingOptionSchema } from '@app/pages/profiles/profile-details/profile-ide-provisioner/schemas/setting-option-schema';
export const SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MIN = 10 * 60; // 10 mins
export const SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MAX = 24 * 60 * 60; // 24 hours
export const SETTINGS_UPDATE_INTERVAL_SECONDS_DEFAULT_VALUE = 2 * 60 * 60; // 2 hours
const tToolboxSettingValueBooleanSchema = z.object({
    value: z.boolean()
});
const tForcedToolboxSettingValueBooleanSchema = tToolboxSettingValueBooleanSchema.extend({
    forced: z.boolean()
});
export const tToolboxSettingsSchema = tSettingOptionSchema.extend({
    content: z.object({
        updateToolsAutomatically: tForcedToolboxSettingValueBooleanSchema
            .nullable()
            .optional()
            .transform(x => x !== null && x !== void 0 ? x : undefined),
        sendAnonymousUserStatistics: tForcedToolboxSettingValueBooleanSchema
            .nullable()
            .optional()
            .transform(x => x !== null && x !== void 0 ? x : undefined),
        updateToolboxAutomatically: tForcedToolboxSettingValueBooleanSchema
            .nullable()
            .optional()
            .transform(x => x !== null && x !== void 0 ? x : undefined),
        settingsUpdateIntervalSeconds: z
            .object({
            value: z
                .number()
                .min(SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MIN, `The minimum allowed value is ${SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MIN / 60} minutes.`)
                .max(SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MAX, `The maximum allowed value is ${SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MAX / 60} minutes (24 hours).`)
        })
            .nullable()
            .optional()
            .transform(x => x !== null && x !== void 0 ? x : undefined),
        showServicesTab: tToolboxSettingValueBooleanSchema
            .nullable()
            .optional()
            .transform(x => x !== null && x !== void 0 ? x : undefined),
        showJetBrainsAccountLogin: tToolboxSettingValueBooleanSchema
            .nullable()
            .optional()
            .transform(x => x !== null && x !== void 0 ? x : undefined)
    })
});
