// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolsPreview__aa0 {
  margin-bottom: var(--ring-unit);
}

.errorMessage__c35 {
  margin-top: var(--ring-unit);
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/applications/application-filters-dialog.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,4BAA4B;EAC5B,8BAA8B;AAChC","sourcesContent":[".toolsPreview {\n  margin-bottom: var(--ring-unit);\n}\n\n.errorMessage {\n  margin-top: var(--ring-unit);\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolsPreview": `toolsPreview__aa0`,
	"errorMessage": `errorMessage__c35`
};
export default ___CSS_LOADER_EXPORT___;
