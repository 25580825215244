import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import classNames from 'classnames';
import Select from '@jetbrains/ring-ui/components/select/select';
import { AI_PROVIDERS } from '@modules/ai/common/constants';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './default-profile-selector.css';
export function DefaultProfileSelector(props) {
    const { className, availableProviders, defaultProvider, onDefaultProviderChange } = props;
    const handleDefaultProviderChange = useCallback((provider) => {
        if (provider) {
            onDefaultProviderChange(provider.key);
        }
    }, [onDefaultProviderChange]);
    return (_jsxs("div", { className: classNames(styles.wrapper, className), children: [_jsx("p", { className: styles.message, children: "You removed the default provider. Please choose a new one." }), _jsx(Select, { selectedLabel: "Default AI provider", data: availableProviders.map(provider => ({
                    ...AI_PROVIDERS[provider],
                    ...assignTestId(provider)
                })), selected: defaultProvider ? [AI_PROVIDERS[defaultProvider]] : undefined, onSelect: handleDefaultProviderChange, className: styles.selector, ...assignTestId('default-provider-select') })] }));
}
