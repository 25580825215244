import { jsx as _jsx } from "react/jsx-runtime";
import { useController, useFormContext } from 'react-hook-form';
import { ToolboxSettingField } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/toolbox-setting-field';
import { assignTestId } from '@common/infra/tests/assign-test-id';
const DEFAULT_UPDATE_TOOLBOX_AUTOMATICALLY_TOOLBOX_SETTING = {
    value: false,
    forced: false
};
export function UpdateToolboxAutomaticallyToolboxSettingField() {
    var _a;
    const { control } = useFormContext();
    const { field: { value: updateToolboxAutomatically, onChange: onUpdateToolboxAutomaticallyChange } } = useController({
        control,
        name: 'content.updateToolboxAutomatically'
    });
    return (_jsx(ToolboxSettingField, { label: "Update Toolbox App automatically", value: updateToolboxAutomatically === null || updateToolboxAutomatically === void 0 ? void 0 : updateToolboxAutomatically.value, forced: (_a = updateToolboxAutomatically === null || updateToolboxAutomatically === void 0 ? void 0 : updateToolboxAutomatically.forced) !== null && _a !== void 0 ? _a : null, onChange: newValue => onUpdateToolboxAutomaticallyChange({
            ...DEFAULT_UPDATE_TOOLBOX_AUTOMATICALLY_TOOLBOX_SETTING,
            ...updateToolboxAutomatically,
            value: newValue
        }), onForcedChange: newForced => {
            if (newForced === null) {
                onUpdateToolboxAutomaticallyChange(null);
            }
            onUpdateToolboxAutomaticallyChange({
                ...DEFAULT_UPDATE_TOOLBOX_AUTOMATICALLY_TOOLBOX_SETTING,
                ...updateToolboxAutomatically,
                forced: newForced
            });
        }, ...assignTestId('update-toolbox-automatically-toolbox-setting-field') }));
}
