import { useMemo } from 'react';
import { useOriginalFilteredMarketplacePlugins, useOriginalMarketplacePlugins, toPluginModel } from '@common/queries/plugins';
import { ExternalLinks } from '../../common/constants/constants';
function convert(plugin) {
    return toPluginModel(plugin.xmlId, 'MARKETPLACE', plugin.name);
}
export function useMarketplacePlugins(pluginIds, enabled = true) {
    const query = useOriginalMarketplacePlugins(pluginIds, enabled);
    const data = useMemo(() => (query.data ? query.data.map(convert) : query.data), [query.data]);
    return { ...query, data };
}
export function useFilteredMarketplacePlugins(filter, enabled = true) {
    const query = useOriginalFilteredMarketplacePlugins(filter, enabled);
    const data = useMemo(() => (query.data ? query.data.map(convert) : query.data), [query.data]);
    return { ...query, data };
}
export function useMarketplacePluginVersionsUrl(xmlId, enabled) {
    const query = useOriginalMarketplacePlugins([xmlId], enabled);
    const data = useMemo(() => {
        if (query.data) {
            return query.data.length > 0
                ? `${ExternalLinks.JB_PLUGINS}${query.data[0].link}/versions`
                : '';
        }
        return query.data;
    }, [query.data]);
    return { ...query, data };
}
