// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialogContent__dea {
  box-sizing: content-box;
}

.dialogContent__dea.wide__b65 {
  width: calc(90 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/common/dialog/dialog.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".dialogContent {\n  box-sizing: content-box;\n}\n\n.dialogContent.wide {\n  width: calc(90 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContent": `dialogContent__dea`,
	"wide": `wide__b65`
};
export default ___CSS_LOADER_EXPORT___;
