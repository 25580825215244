import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { formatDistance } from 'date-fns';
import { TitledBlock } from '@common/titled-block/titled-block';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { getTrialEndsInterval } from '../common/trial/get-trial-ends-interval';
import styles from './constituent-block.css';
export function ConstituentBlock(props) {
    const { children, titleLogo, title, state, notConfigured, className, trialData } = props;
    return (_jsx(TitledBlock, { title: _jsxs("span", { className: styles.titleWrapper, children: [title, " ", _jsx(StateBadge, { state: state }), notConfigured && (_jsx("span", { className: classNames(styles.badge, styles.warn), children: "Configuration Required" })), trialBadgeShouldBeShown(trialData) && _jsx(TrialEndsBadge, { trial: trialData.license.trial })] }), titleLogo: titleLogo, className: className, ...assignTestId('constituent-block', props), children: children }));
}
function StateBadge(props) {
    const { state } = props;
    switch (state) {
        case 'enabled':
            return _jsx("span", { className: classNames(styles.badge, styles.enabled), children: "Enabled" });
        case 'disabled':
            return _jsx("span", { className: classNames(styles.badge, styles.disabled), children: "Disabled" });
        default:
            throw new Error(`Unknown state: ${state}`);
    }
}
function TrialEndsBadge(props) {
    const { trial } = props;
    if (!trial.endDate) {
        return null;
    }
    const trialEndInterval = getTrialEndsInterval(trial.endDate);
    return (_jsxs("span", { className: classNames(styles.badge, {
            [styles.primary]: trialEndInterval === 'moreThanOneMonth',
            [styles.warning]: trialEndInterval === 'oneMonth',
            [styles.error]: trialEndInterval === 'twoWeeks'
        }), ...assignTestId('trial-end-date'), children: ["Trial ends in ", formatDistance(trial.endDate, new Date())] }));
}
function trialBadgeShouldBeShown(trialData) {
    if (!trialData) {
        return false;
    }
    const { licenseType, license } = trialData;
    if (licenseType === 'POSTPAID' && license.trial.state === 'ACTIVE') {
        return true;
    }
    if (license.trial.state === 'ACTIVE') {
        return true;
    }
    return false;
}
