import { jsx as _jsx } from "react/jsx-runtime";
import Input, { Size as InputSize } from '@jetbrains/ring-ui/components/input/input';
import searchIcon from '@jetbrains/icons/search';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useDebounceComponentValue } from '../hooks/use-debounce-component-value';
function SearchFilter({ value, onChange, placeholder = undefined, className = undefined, size = InputSize.L, label = '', ...rest }) {
    const [rawValue, setRawValue] = useDebounceComponentValue(value, onChange, 300);
    return (_jsx(Input, { placeholder: placeholder, className: className, icon: searchIcon, value: rawValue, onChange: event => setRawValue(event.target.value), onClear: () => setRawValue(''), size: size, label: label, ...rest, ...assignTestId('search-input') }));
}
export default SearchFilter;
