// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usersContainer__e1d {
  display: flex;
  gap: calc(var(--ring-unit) / 2);
  margin-bottom: calc(var(--ring-unit) * 2.5);
}

.warningText__ca0.warningText__ca0,
.warningText__ca0 svg {
  color: var(--ring-warning-color);
  display: flex;
  gap: calc(var(--ring-unit) / 2);
}

.links__d60 {
  display: flex;
  gap: var(--ring-unit);
}

.popupContainer__a82 {
  display: flex;
  flex-direction: column;
  gap: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/active-users.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,2CAA2C;AAC7C;;AAEA;;EAEE,gCAAgC;EAChC,aAAa;EACb,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB","sourcesContent":[".usersContainer {\n  display: flex;\n  gap: calc(var(--ring-unit) / 2);\n  margin-bottom: calc(var(--ring-unit) * 2.5);\n}\n\n.warningText.warningText,\n.warningText svg {\n  color: var(--ring-warning-color);\n  display: flex;\n  gap: calc(var(--ring-unit) / 2);\n}\n\n.links {\n  display: flex;\n  gap: var(--ring-unit);\n}\n\n.popupContainer {\n  display: flex;\n  flex-direction: column;\n  gap: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"usersContainer": `usersContainer__e1d`,
	"warningText": `warningText__ca0`,
	"links": `links__d60`,
	"popupContainer": `popupContainer__a82`
};
export default ___CSS_LOADER_EXPORT___;
