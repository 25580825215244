import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import Button from '@jetbrains/ring-ui/components/button/button';
import { redirect, useLoaderData, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AiSettingsForm } from '@modules/ai/ai-settings-form/ai-settings-form';
import { AiDisableDialog } from '@modules/ai/common/dialogs/ai-disable-dialog/ai-disable-dialog';
import { tAiSettingsFormSchema } from '@modules/ai/ai-settings-form/ai-settings-form-schema';
import { AiAvailableProviders } from '@modules/ai/ai-settings-form/sections';
import { MigrationDialog } from '@modules/ai/ai-settings-form/migration-dialog/migration-dialog';
import { PageTitle } from '@common/page-layout/page-title';
import { ensureAiConstituentQueryData } from '@common/queries/ai-enterprise';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useOpenModal } from '@common/modals/hooks';
import { RouteLeavingGuard } from '@common/route-leaving-guard/route-leaving-guard';
import styles from './ai-settings-page.css';
export async function AiSettingsPageResolver() {
    const constituent = await ensureAiConstituentQueryData();
    if (constituent.status !== 'enabled') {
        return redirect('/configuration/license-activation');
    }
    return constituent;
}
export function AiSettingsPage() {
    const navigate = useNavigate();
    const aiConstituent = useLoaderData();
    const [disableOpen, setDisableOpen] = useState(false);
    const openModal = useOpenModal(AiDisableDialog, {});
    const handleDisableAi = useCallback(() => {
        setDisableOpen(true);
        openModal().then(success => {
            if (success) {
                navigate('/configuration/license-activation');
            }
            else {
                setDisableOpen(false);
            }
        });
    }, [openModal, navigate]);
    const form = useForm({
        defaultValues: aiConstituent.data,
        resolver: zodResolver(tAiSettingsFormSchema)
    });
    const { availableProviders } = aiConstituent.data;
    const openMigrationDialog = useOpenModal(MigrationDialog, {
        previousState: aiConstituent.data,
        stateUpdate: aiConstituent.data
    });
    const handleSave = useCallback((newState) => {
        openMigrationDialog({ stateUpdate: newState }).then(savedConstituentState => {
            if (savedConstituentState) {
                if (savedConstituentState.status === 'enabled') {
                    form.reset(savedConstituentState.data);
                }
                else {
                    navigate('../');
                }
            }
        });
    }, [openMigrationDialog, form, navigate]);
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: "AI Enterprise Settings ", breadcrumbs: [
                    { title: 'Configuration', href: '/configuration' },
                    { title: 'License and Activation', href: '/configuration/license-activation' }
                ], rightPanel: _jsx(Button, { danger: true, onClick: handleDisableAi, ...assignTestId('disable-button'), children: "Disable" }), className: styles.title }), _jsxs("div", { className: styles.formWrapper, children: [_jsx(AiSettingsForm, { className: styles.form, form: form, onFormSave: handleSave, availableProviders: availableProviders }), _jsx(AiAvailableProviders, { availableProviders: availableProviders, className: styles.availableProviders })] }), _jsx(RouteLeavingGuard, { when: form.formState.isDirty && !disableOpen })] }));
}
