import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import Group from '@jetbrains/ring-ui/components/group/group';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import Text from '@jetbrains/ring-ui/components/text/text';
import { noop } from 'lodash';
import { UsersProfileButton } from '@app/pages/profiles/profile-details/profile-ide-provisioner/common/users-profile-button';
import { getProfileSettings, getImmutableProfileConstituents } from '@app/common/store/profiles/selectors';
import confirmProfileDeletion from '@app/pages/profiles/profile-details/profile-ide-provisioner/confirm-profile-deletion';
import { useDeleteProfileMutation, useProfileDetails, useUsersByProfileId } from '@app/common/queries/profiles';
import ProfileEditDialog from '@app/pages/profiles/profile-details/profile-ide-provisioner/profile-edit-dialog';
import { useSynchronizedProfilesState } from '@app/pages/profiles/profile-details/profile-ide-provisioner/hooks/use-profile-synchronization';
import { ProfilesBanner } from '@app/pages/profiles/profile-details/banner/profiles-banner';
import NewlyIncludedAppsBanner from '@app/pages/profiles/profile-details/banner/newly-included-apps-banner';
import TbaBanner from '@app/pages/profiles/profile-details/banner/tba-banner';
import OfflineModeBanner from '@app/pages/profiles/profile-details/banner/offline-mode-banner';
import { useCodeWithMeConstituent } from '@app/common/queries/code-with-me';
import { useIdeProvisionerConstituent } from '@app/common/queries/ide-provisioner';
import { useAiConstituent } from '@app/common/queries/ai-enterprise';
import { PageTitle } from '@app/common/page-layout/page-title';
import { RouteTab, RouteTabs } from '@app/common/route-tabs';
import auth from '@app/common/auth/auth';
import { assignTestId } from '@app/common/infra/tests/assign-test-id';
import { isConstituentAllowedToUseByLicense } from '@modules/constituents/is-constituent-allowed-to-use-by-license';
import { useTbeLicense } from '@common/queries/tbe-license';
import { ProfileActions } from '@common/store/profiles/profile-reducer';
import { ProfileDropdownMenu } from './profile-dropdown-menu/profile-dropdown-menu';
import styles from './profile-details-page.css';
export var ProfileTabPath;
(function (ProfileTabPath) {
    ProfileTabPath["IDE_PROVISIONER"] = "provisioner";
    ProfileTabPath["CODE_WITH_ME"] = "cwm";
    ProfileTabPath["AI"] = "ai";
})(ProfileTabPath || (ProfileTabPath = {}));
export function ProfileDetailsPage() {
    var _a;
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    const { title: profileTitle, description } = useSelector(getProfileSettings);
    const profileConstituents = useSelector(getImmutableProfileConstituents);
    const { id } = useParams();
    const { data: license } = useTbeLicense();
    const deleteMutation = useDeleteProfileMutation();
    const { data: cwmConstituentState, isLoading: isCwmStateLoading } = useCodeWithMeConstituent();
    const { data: idepConstituentState, isLoading: isIdepStateLoading } = useIdeProvisionerConstituent();
    const { data: aiConstituentState, isLoading: isAiStateLoading } = useAiConstituent();
    const idePInactive = isIdepStateLoading ||
        !((idepConstituentState === null || idepConstituentState === void 0 ? void 0 : idepConstituentState.status) === 'enabled' && profileConstituents.idep.isEnabled);
    const cwmInactive = isCwmStateLoading ||
        !((cwmConstituentState === null || cwmConstituentState === void 0 ? void 0 : cwmConstituentState.status) === 'enabled' && profileConstituents.cwm.isEnabled);
    const aiInactive = isAiStateLoading ||
        !((aiConstituentState === null || aiConstituentState === void 0 ? void 0 : aiConstituentState.status) === 'enabled' && profileConstituents.ai.isEnabled);
    const cwmLicense = license === null || license === void 0 ? void 0 : license.constituents.find(c => c.id === 'cwm');
    const cwmTabVisible = !!cwmLicense &&
        isConstituentAllowedToUseByLicense(cwmLicense) &&
        !isCwmStateLoading &&
        (cwmConstituentState === null || cwmConstituentState === void 0 ? void 0 : cwmConstituentState.status) !== 'disabled_jba';
    const aiLicense = license === null || license === void 0 ? void 0 : license.constituents.find(c => c.id === 'ai');
    const aiTabVisible = !!aiLicense &&
        isConstituentAllowedToUseByLicense(aiLicense) &&
        !isAiStateLoading &&
        (aiConstituentState === null || aiConstituentState === void 0 ? void 0 : aiConstituentState.status) !== 'disabled_jba' &&
        !offlineModeEnabled;
    const enableFetching = !(deleteMutation.isLoading || deleteMutation.isSuccess);
    const { data, isLoading: profileDetailsLoading, error } = useProfileDetails(id, enableFetching);
    const useUsersFetchResponse = useUsersByProfileId(id, undefined, enableFetching);
    const [showProfileDialog, setShowProfileDialog] = useState(false);
    const usersInProfileCount = useMemo(() => { var _a, _b; return (_b = (_a = useUsersFetchResponse.data) === null || _a === void 0 ? void 0 : _a.items.length) !== null && _b !== void 0 ? _b : 0; }, [useUsersFetchResponse]);
    useSynchronizedProfilesState(data);
    const handleDelete = useCallback(async () => {
        return deleteMutation.mutateAsync({ profileId: id, profileTitle }).catch(noop);
    }, [deleteMutation, id, profileTitle]);
    const handleDeleteProfile = useCallback(() => {
        return confirmProfileDeletion(profileTitle, usersInProfileCount)
            .then(handleDelete)
            .catch(hideConfirm);
    }, [profileTitle, usersInProfileCount, handleDelete]);
    const handleEditProfile = useCallback(() => setShowProfileDialog(true), []);
    const dispatch = useDispatch();
    const handleCwmActivationChange = useCallback((enabled) => dispatch(ProfileActions.updateCwmConstituent({ isEnabled: enabled })), [dispatch]);
    const handleIdeProvisionerActivationChange = useCallback((enabled) => dispatch(ProfileActions.updateIDEPConstituent({ isEnabled: enabled })), [dispatch]);
    const handleAiActivationChange = useCallback((enabled) => dispatch(ProfileActions.updateAiConstituent({ isEnabled: enabled })), [dispatch]);
    if (deleteMutation.isSuccess) {
        return _jsx(Navigate, { to: "/profiles" });
    }
    if (profileDetailsLoading) {
        return _jsx(Loader, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: profileTitle, breadcrumbs: [{ title: 'Profiles', href: '/profiles' }], className: styles.pageTitle, rightPanel: _jsxs(Group, { children: [_jsx(UsersProfileButton, { useUsersFetch: useUsersFetchResponse, enabled: enableFetching }), _jsx(ProfileDropdownMenu, { profileId: id, editProfile: handleEditProfile, deleteProfile: handleDeleteProfile, onCwmActivationChange: handleCwmActivationChange, onIdeProvisionerActivationChange: handleIdeProvisionerActivationChange, onAiActivationChange: handleAiActivationChange })] }) }), description && _jsx("div", { className: styles.description, children: description }), _jsxs("div", { className: styles.banners, children: [_jsx(ProfilesBanner, { is404: ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === 404, allowedRoutes: [
                            '/profiles/:id',
                            `/profiles/:id/${ProfileTabPath.IDE_PROVISIONER}`,
                            `/profiles/:id/${ProfileTabPath.CODE_WITH_ME}`,
                            `/profiles/:id/${ProfileTabPath.AI}`
                        ] }), _jsx(NewlyIncludedAppsBanner, {}), _jsx(TbaBanner, {}), _jsx(OfflineModeBanner, {})] }), (cwmTabVisible || aiTabVisible) && (_jsxs(RouteTabs, { className: styles.tabs, ...assignTestId('profile-tabs'), children: [_jsx(RouteTab, { path: ProfileTabPath.IDE_PROVISIONER, title: _jsx(TabName, { name: "IDE Provisioner", inactive: idePInactive }), titleProps: assignTestId('ide-provisioner-tab', { 'idep-inactive': idePInactive }) }), cwmTabVisible && (_jsx(RouteTab, { path: ProfileTabPath.CODE_WITH_ME, title: _jsx(TabName, { name: "Code With Me", inactive: cwmInactive }), titleProps: assignTestId('code-with-me-tab', { 'cwm-inactive': cwmInactive }) })), aiTabVisible && (_jsx(RouteTab, { path: ProfileTabPath.AI, title: _jsx(TabName, { name: "AI Enterprise", inactive: aiInactive }), titleProps: { ...assignTestId('ai-enterprise-tab', { 'ai-inactive': aiInactive }) } }))] })), _jsx("div", { className: styles.contentWrapper, children: enableFetching && _jsx(Outlet, {}) }), deleteMutation.isLoading && _jsx(Loader, {}), showProfileDialog && _jsx(ProfileEditDialog, { onReject: () => setShowProfileDialog(false) })] }));
}
function TabName(props) {
    const { name, inactive } = props;
    return (_jsxs(_Fragment, { children: [name, inactive && (_jsx(Text, { info: true, className: styles.inactiveLabel, children: "Disabled" }))] }));
}
