import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Button } from '@jetbrains/ring-ui/components/button/button';
import { useModalsController } from '@common/modals';
import HorizontalContainer from '@common/page-layout/horizontal-container';
import UploadPlugin from '../upload-plugin/upload-plugin';
export function PluginVersionActions({ selectedPlugins, isLoading, onRemove }) {
    const modalsController = useModalsController();
    const handleUploadPlugin = async () => {
        const uploadPluginDialog = modalsController.openModal(UploadPlugin, {
            title: 'Upload Plugin Version',
            onClose() {
                uploadPluginDialog.closeModal();
            }
        });
    };
    const removeButtonText = useMemo(() => {
        const selectionSize = selectedPlugins.size;
        switch (selectionSize) {
            case 0:
                return `Remove selected`;
            case 1:
                return 'Remove 1 version';
            default:
                return `Remove ${selectionSize} versions`;
        }
    }, [selectedPlugins]);
    return (_jsxs(HorizontalContainer, { children: [_jsx(Button, { primary: true, "data-test": "upload-plugin-version", onClick: handleUploadPlugin, children: "Upload plugin version" }), _jsx(Button, { "data-test": "remove-selected-plugin-versions-button", loader: isLoading, disabled: !selectedPlugins.size, danger: true, onClick: () => onRemove(Array.from(selectedPlugins)), children: removeButtonText })] }));
}
