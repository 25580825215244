// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message__dca + .selectorWrapper__e1a {
  margin-top: var(--ring-unit);
}

.selector__d91 + .icon__a6d,
.icon__a6d + .selector__d91 {
  margin-left: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/ai-settings-form/migration-dialog/migration-strategy-selector/migration-strategy-selector.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;;EAEE,6BAA6B;AAC/B","sourcesContent":[".message + .selectorWrapper {\n  margin-top: var(--ring-unit);\n}\n\n.selector + .icon,\n.icon + .selector {\n  margin-left: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `message__dca`,
	"selectorWrapper": `selectorWrapper__e1a`,
	"selector": `selector__d91`,
	"icon": `icon__a6d`
};
export default ___CSS_LOADER_EXPORT___;
