import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog';
import { Content, Header } from '@jetbrains/ring-ui/components/island/island';
import Button from '@jetbrains/ring-ui/components/button/button';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import { useDialogCancelConfirmation } from '@app/common/hooks/use-dialog-cancel-confirmation';
import { getDefaultToolboxSettings } from '@app/pages/profiles/profile-details/profile-ide-provisioner/schemas/ctors';
import { tToolboxSettingsSchema } from '@app/pages/profiles/profile-details/profile-ide-provisioner/schemas/toolbox-settings-schema';
import { UpdateToolsAutomaticallyToolboxSettingField } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/update-tools-automatically-toolbox-setting-field';
import { SendAnonymousUserStatisticsSettingField } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/send-anonymous-user-statistics-setting-field';
import { UpdateToolboxAutomaticallyToolboxSettingField } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/update-toolbox-automatically-toolbox-setting-field';
import { SettingsUpdateIntervalSettingField } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/settings-update-interval-setting-field';
import { ShowServicesTabSettingField } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/show-services-tab-setting-field';
import { ShowJetBrainsAccountSettingField } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/show-jetbrains-account-setting-field';
import styles from './profiles-toolbox-settings-dialog-form.css';
export function ToolboxSettingsDialogForm({ item, onReject, onSubmit }) {
    const form = useForm({
        defaultValues: item !== null && item !== void 0 ? item : getDefaultToolboxSettings(),
        resolver: zodResolver(tToolboxSettingsSchema)
    });
    const { formState: { isDirty, isSubmitting }, handleSubmit } = form;
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(onReject, isDirty);
    return (_jsxs(Dialog, { show: true, autoFocusFirst: false, onCloseAttempt: onCloseAttempt, contentClassName: styles.dialogContainer, children: [_jsx(Header, { children: "Configure Toolbox Settings" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(UpdateToolboxAutomaticallyToolboxSettingField, {}), _jsx(UpdateToolsAutomaticallyToolboxSettingField, {}), _jsx(SendAnonymousUserStatisticsSettingField, {}), _jsx(ShowServicesTabSettingField, {}), _jsx(ShowJetBrainsAccountSettingField, {}), _jsx(SettingsUpdateIntervalSettingField, {})] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(onSubmit), type: "submit", loader: isSubmitting, disabled: !isDirty, children: "Save" }), _jsx(Button, { ref: cancelButtonRef, onClick: onReject, children: "Cancel" })] })] }));
}
