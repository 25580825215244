import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { parseISO } from 'date-fns';
import { isEmpty, noop } from 'lodash';
import ProgressBar from '@jetbrains/ring-ui/components/progress-bar/progress-bar';
import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import downloadIcon from '@jetbrains/icons/download';
import removeIcon from '@jetbrains/icons/trash';
import linkIcon from '@jetbrains/icons/link';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { useNavigate } from 'react-router-dom';
import { RouteLeavingGuard } from '@app/common/route-leaving-guard/route-leaving-guard';
import { BasicSelectableTable } from '@common/tables/basic-selectable-table';
import { confirmPluginDeletion, confirmPluginVersionsDeletion } from '../popups/confirmations';
import { invalidatePluginDownloads, useDeletePluginMutation, useDeletePluginVersionsMutation, usePluginVersions } from '../../../common/queries/plugins';
import HelpIcon from '../../../common/util/help-icon';
import { TableActionButton } from '../../../common/tables/table-action-button';
import { formatDateAndTime, formatProductVersion } from '../../../common/util/i18n';
import { downloadBlob } from '../../../common/util/blob';
import { getPluginAsBlob } from '../../../api/plugins';
import { withSortableColumnFlag } from '../../../common/tables/table-columns';
import InstallPluginDialog from '../popups/install-plugin-dialog';
import ExpirationDateEditorWrapper from './expiration-date-editor-wrapper';
import ChannelsEditorWrapper from './channels-editor-wrapper';
import { PluginVersionActions } from './plugin-version-actions';
import { usePluginParams } from './use-plugin-params';
import { sortableColumns } from './constants';
import styles from './plugin-page.css';
export function columnToApiSortName(column) {
    switch (column) {
        case sortableColumns.version:
            return 'PLUGIN_VERSION';
        case sortableColumns.formattedSize:
            return 'PLUGIN_SIZE';
        case sortableColumns.uploadDate:
            return 'UPLOAD_DATE';
        case sortableColumns.expirationDate:
            return 'EXPIRATION_DATE';
        default:
            return undefined;
    }
}
const toApiSortParams = (sortParams) => ({
    sort: columnToApiSortName(sortParams.sortKey),
    order: sortParams.sortOrder
});
export function getDownloadingPlugin(url, progress) {
    return { url, progress };
}
function buildCompatibleVersions(ijVersionMin, ijVersionMax) {
    const isMinPopulated = !isEmpty(ijVersionMin);
    const isMaxPopulated = !isEmpty(ijVersionMax);
    if (isMinPopulated && isMaxPopulated && ijVersionMin === ijVersionMax) {
        return formatProductVersion(ijVersionMin);
    }
    if (isMinPopulated && isMaxPopulated) {
        return `${formatProductVersion(ijVersionMin)} - ${formatProductVersion(ijVersionMax)}`;
    }
    if (!isMinPopulated && isMaxPopulated) {
        return `Lower than ${formatProductVersion(ijVersionMax)}`;
    }
    if (isMinPopulated && !isMaxPopulated) {
        return `Higher than ${formatProductVersion(ijVersionMin)}`;
    }
    return 'All versions';
}
export default function PluginVersions({ plugin }) {
    var _a;
    const navigate = useNavigate();
    const [urlParams, setUrlParams] = usePluginParams();
    const [selectedPlugins, setSelectedPlugins] = useState(new Set());
    const [beingLoadedPlugins, setBeingLoadedPlugins] = useState([]);
    const { data: pluginVersionsPage, isLoading } = usePluginVersions(plugin.id, toApiSortParams(urlParams));
    const deleteVersionsMutation = useDeletePluginVersionsMutation();
    const deletePluginMutation = useDeletePluginMutation();
    const [versionForCopyLinkDialog, setVersionForCopyLinkDialog] = useState();
    const onPluginBeingLoadedChange = (nextPlugin) => {
        setBeingLoadedPlugins(prev => prev.map(it => (it.url === nextPlugin.url ? nextPlugin : it)));
    };
    const onRemoveVersions = useCallback((versions) => {
        var _a;
        if (versions.length === ((_a = pluginVersionsPage === null || pluginVersionsPage === void 0 ? void 0 : pluginVersionsPage.items) === null || _a === void 0 ? void 0 : _a.length)) {
            return confirmPluginDeletion(plugin, true)
                .then(() => {
                return deletePluginMutation.mutateAsync(plugin.id, {
                    onSuccess: () => {
                        navigate('/plugins');
                    }
                });
            })
                .catch(noop);
        }
        return confirmPluginVersionsDeletion(plugin, versions)
            .then(() => deleteVersionsMutation.mutateAsync({
            id: plugin.id,
            versions
        }))
            .catch(noop);
    }, [deletePluginMutation, deleteVersionsMutation, navigate, plugin, pluginVersionsPage]);
    const columns = useMemo(() => {
        const handlePluginsFeedLink = (url, filename) => {
            setBeingLoadedPlugins(x => x.concat(getDownloadingPlugin(url, 0)));
            return getPluginAsBlob(url, progress => onPluginBeingLoadedChange(getDownloadingPlugin(url, progress))).then(response => {
                if (response.status === 200) {
                    downloadBlob(new Blob([response.data]), filename);
                }
                else {
                    alert.error(`Couldn't download: ${filename}`);
                }
                setBeingLoadedPlugins(x => x.filter(y => y.url !== url));
                invalidatePluginDownloads(plugin.id).then();
            });
        };
        return withSortableColumnFlag([
            {
                id: sortableColumns.version,
                title: 'Plugin Version'
            },
            {
                id: sortableColumns.formattedSize,
                title: 'Plugin Size',
                rightAlign: true
            },
            {
                id: 'channels',
                title: (_jsxs(_Fragment, { children: [_jsx("span", { className: styles.channelsText, children: "Channels" }), _jsx(Tooltip, { title: _jsxs(_Fragment, { children: ["A channel represents a stream of plugin versions. When you upload a plugin version to channels other than ", _jsx("code", { children: "Stable" }), ", all authorized developers will be able to install it."] }), children: _jsx(HelpIcon, {}) })] })),
                getValue({ channels, id, version }) {
                    return (_jsx(ChannelsEditorWrapper, { initialChannels: channels, pluginVersion: version, pluginId: plugin.id }, `channnels-editor-${id}`));
                }
            },
            {
                id: 'compatibleVersions',
                title: 'Compatible IDEs',
                getValue({ ijVersionMin, ijVersionMax }) {
                    return ijVersionMin !== undefined && ijVersionMax !== undefined
                        ? buildCompatibleVersions(ijVersionMin, ijVersionMax)
                        : null;
                }
            },
            {
                id: sortableColumns.uploadDate,
                title: 'Upload Date',
                rightAlign: true,
                getValue({ uploadDate }) {
                    return uploadDate && formatDateAndTime(uploadDate);
                }
            },
            {
                id: sortableColumns.expirationDate,
                title: 'Expiration Date',
                rightAlign: true,
                getValue({ version, expirationDate }) {
                    return (_jsx(ExpirationDateEditorWrapper, { pluginId: plugin.id, pluginVersion: version, expirationDate: expirationDate ? parseISO(expirationDate) : null }));
                }
            },
            {
                id: 'actions',
                getValue(version) {
                    var _a, _b;
                    const url = `/api/plugin-repository/${encodeURIComponent(plugin.id)}/${encodeURIComponent(version.version)}`;
                    const downloadingOperation = beingLoadedPlugins.find(x => x.url === url);
                    const filename = `${plugin.id}-${version.version}.${version.fileExtension}`;
                    return (_jsxs(_Fragment, { children: [_jsx("span", { className: styles.downloadProgressBarWrapper, children: downloadingOperation ? (_jsx(ProgressBar, { label: "Progress", value: downloadingOperation.progress })) : (_jsx(TableActionButton, { title: "Download version", text: true, icon: downloadIcon, action: () => handlePluginsFeedLink(url, filename) })) }), _jsx(TableActionButton, { text: true, title: "Copy plugin installation link", icon: linkIcon, action: () => setVersionForCopyLinkDialog(version) }), _jsx(TableActionButton, { "data-test": "remove-plugin-version-button", text: true, title: "Remove version", danger: true, loader: deleteVersionsMutation.isLoading &&
                                    ((_b = (_a = deleteVersionsMutation.variables) === null || _a === void 0 ? void 0 : _a.versions) === null || _b === void 0 ? void 0 : _b.some(x => x.id === version.id)), icon: removeIcon, action: () => onRemoveVersions([version]) })] }));
                }
            }
        ], sortableColumns);
    }, [
        plugin.id,
        beingLoadedPlugins,
        deleteVersionsMutation.isLoading,
        (_a = deleteVersionsMutation.variables) === null || _a === void 0 ? void 0 : _a.versions,
        onRemoveVersions
    ]);
    const handleOnSort = (sortParams) => {
        setUrlParams({
            sortKey: sortParams.column.id,
            sortOrder: sortParams.order ? 'ASC' : 'DESC'
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(PluginVersionActions, { selectedPlugins: selectedPlugins, isLoading: deleteVersionsMutation.isLoading || isLoading, onRemove: onRemoveVersions }), (pluginVersionsPage === null || pluginVersionsPage === void 0 ? void 0 : pluginVersionsPage.items) && (_jsx(BasicSelectableTable, { loading: !!beingLoadedPlugins.length || deletePluginMutation.isLoading || isLoading, data: pluginVersionsPage === null || pluginVersionsPage === void 0 ? void 0 : pluginVersionsPage.items, getItemKey: pluginVersionItem => pluginVersionItem.id, onSort: handleOnSort, columns: columns, selectable: true, onSelect: selection => setSelectedPlugins(selection.getSelected()), sortKey: urlParams.sortKey, sortOrder: urlParams.sortOrder === 'ASC' })), _jsx(RouteLeavingGuard, { when: beingLoadedPlugins.length > 0 }), versionForCopyLinkDialog && (_jsx(InstallPluginDialog, { plugin: plugin, version: versionForCopyLinkDialog, onClose: () => {
                    setVersionForCopyLinkDialog(undefined);
                } }))] }));
}
