import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Icon from '@jetbrains/ring-ui/components/icon';
import { H2, H3 } from '@jetbrains/ring-ui/components/heading/heading';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { ParagraphLoader } from '@common/loaders/paragraph-loader';
import styles from './widget.css';
export var WidgetHighlightingColor;
(function (WidgetHighlightingColor) {
    WidgetHighlightingColor["Warning"] = "warning";
})(WidgetHighlightingColor || (WidgetHighlightingColor = {}));
export var WidgetSize;
(function (WidgetSize) {
    WidgetSize["Wide"] = "wide";
    WidgetSize["Medium"] = "medium";
})(WidgetSize || (WidgetSize = {}));
export var TitleSize;
(function (TitleSize) {
    TitleSize["MEDIUM"] = "medium";
    TitleSize["LARGE"] = "large";
})(TitleSize || (TitleSize = {}));
export var WidgetVariant;
(function (WidgetVariant) {
    WidgetVariant["Filled"] = "filled";
    WidgetVariant["Outlined"] = "outlined";
})(WidgetVariant || (WidgetVariant = {}));
export var JustifyContent;
(function (JustifyContent) {
    JustifyContent["Top"] = "top";
    JustifyContent["Bottom"] = "bottom";
})(JustifyContent || (JustifyContent = {}));
export function Widget(props) {
    const { children, widgetSize, controls, variant = WidgetVariant.Outlined, icon, title, titleSize = TitleSize.MEDIUM, highlighting, loading, titleHint, className, justifyContent = JustifyContent.Top, topRightContent, ...rest } = props;
    return (_jsxs("div", { className: classNames(styles.wrapper, className, {
            [styles.wide]: widgetSize === WidgetSize.Wide,
            [styles.medium]: widgetSize === WidgetSize.Medium,
            [styles.filled]: variant === WidgetVariant.Filled,
            [styles.outlined]: variant === WidgetVariant.Outlined,
            [styles.highlighted]: !!highlighting,
            [styles.warning]: highlighting === WidgetHighlightingColor.Warning,
            [styles.contentTop]: justifyContent === JustifyContent.Top,
            [styles.contentBottom]: justifyContent === JustifyContent.Bottom,
            [styles.loading]: loading
        }), ...assignTestId('widget', rest), children: [topRightContent && _jsx("div", { className: styles.absoluteContent, children: topRightContent }), _jsxs("header", { className: styles.header, children: [icon && (_jsx("div", { className: classNames(styles.iconWrapper, {
                            [styles.simpleIcon]: typeof icon === 'string'
                        }), children: typeof icon === 'string' ? _jsx(Icon, { glyph: icon, size: Icon.Size.Size20 }) : icon })), widgetSize === WidgetSize.Wide && (_jsx(H2, { className: classNames(styles.title, {
                            [styles.titleMediumSize]: titleSize === TitleSize.MEDIUM,
                            [styles.titleLargeSize]: titleSize === TitleSize.LARGE
                        }), title: titleHint, ...assignTestId('title'), children: title })), widgetSize === WidgetSize.Medium && (_jsx(H3, { className: classNames(styles.title, {
                            [styles.titleMediumSize]: titleSize === TitleSize.MEDIUM,
                            [styles.titleLargeSize]: titleSize === TitleSize.LARGE
                        }), title: titleHint, ...assignTestId('title'), children: title }))] }), _jsx("div", { className: styles.mainContent, children: loading ? _jsx(ParagraphLoader, {}) : children }), controls && (_jsx("div", { className: styles.controls, ...assignTestId('controls'), children: controls }))] }));
}
