// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message__cd4 + .selector__d11 {
  margin-top: var(--ring-unit);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/ai-settings-form/migration-dialog/default-profile-selector/default-profile-selector.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B","sourcesContent":[".message + .selector {\n  margin-top: var(--ring-unit);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `message__cd4`,
	"selector": `selector__d11`
};
export default ___CSS_LOADER_EXPORT___;
