// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__a40 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 2.5);
}

.button__bff {
  width: fit-content;
}

.textContainer__d3c {
  max-width: calc(var(--ring-unit) * 32.75);
}

.downloadToolboxContainer__a29 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 2);
}
`, "",{"version":3,"sources":["webpack://./src/modules/applications/widgets/toolbox-app-widget/toolbox-app-widget.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;AACjC","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 2.5);\n}\n\n.button {\n  width: fit-content;\n}\n\n.textContainer {\n  max-width: calc(var(--ring-unit) * 32.75);\n}\n\n.downloadToolboxContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__a40`,
	"button": `button__bff`,
	"textContainer": `textContainer__d3c`,
	"downloadToolboxContainer": `downloadToolboxContainer__a29`
};
export default ___CSS_LOADER_EXPORT___;
