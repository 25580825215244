import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@jetbrains/ring-ui/components/button/button';
import { ConstituentBlock } from '@modules/constituents/constituent-block';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { AiLogo } from '../../../common/ai-logo/ai-logo';
import styles from './ai-disabled.css';
export function AiDisabled(props) {
    const { onSetupClick, className, trialData } = props;
    return (_jsxs(ConstituentBlock, { className: className, title: "AI Enterprise", titleLogo: _jsx(AiLogo, {}), state: "disabled", trialData: trialData, ...assignTestId('ai-block'), children: [_jsx("p", { children: "AI Enterprise offers AI-powered features within JetBrains IDEs for developers in your organization" }), _jsx(Button, { className: styles.button, onClick: onSetupClick, "data-test": "enable-button", children: "Enable..." })] }));
}
