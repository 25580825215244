// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.availableProvidersContainer__dd3 {
  flex-direction: column;
  display: flex;
  gap: var(--ring-unit);
  background-color: var(--ring-sidebar-background-color);
  padding: calc(var(--ring-unit) * 3);
  border-radius: calc(var(--ring-border-radius) * 2);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/ai-settings-form/sections/ai-available-providers/ai-available-providers.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,qBAAqB;EACrB,sDAAsD;EACtD,mCAAmC;EACnC,kDAAkD;AACpD","sourcesContent":[".availableProvidersContainer {\n  flex-direction: column;\n  display: flex;\n  gap: var(--ring-unit);\n  background-color: var(--ring-sidebar-background-color);\n  padding: calc(var(--ring-unit) * 3);\n  border-radius: calc(var(--ring-border-radius) * 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"availableProvidersContainer": `availableProvidersContainer__dd3`
};
export default ___CSS_LOADER_EXPORT___;
