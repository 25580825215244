import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@jetbrains/ring-ui/components/button/button';
import { useNavigate } from 'react-router-dom';
import refreshIcon from '@jetbrains/icons/update';
import copyIcon from '@jetbrains/icons/copy';
import timeIcon from '@jetbrains/icons/time';
import Group from '@jetbrains/ring-ui/components/group/group';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import classNames from 'classnames';
import { useOpenModal } from '@common/modals/hooks';
import { PageTitle } from '@common/page-layout/page-title';
import { useSynchronizePluginsStateMutation } from '@common/queries/plugins';
import iconLoaders from '@common/loaders/icon-loaders.css';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import ConfigureCustomChannelDialog from './popups/configure-custom-channel-dialog';
import PluginsPageBody from './plugins-page-body';
export default function PluginsPage() {
    const navigate = useNavigate();
    const synchronizeMutation = useSynchronizePluginsStateMutation();
    const configureCustomChannel = useOpenModal(ConfigureCustomChannelDialog, {});
    const handleNavigate = () => {
        navigate('/plugins/audit-logs');
    };
    const handleSynchronize = () => {
        synchronizeMutation.mutate();
    };
    return (_jsxs("div", { children: [_jsx(PageTitle, { title: "Plugins", rightPanel: _jsxs(Group, { children: [_jsx(Tooltip, { title: "Copy plugin repository URL", children: _jsx(Button, { icon: copyIcon, onClick: () => configureCustomChannel(), ...assignTestId('copy-plugin-repository-url-button') }) }), _jsx(Tooltip, { title: "Initiate synchronization between plugins in the object storage and the database in case you\n            notice any data inconsistency", children: _jsx(Button, { icon: refreshIcon, onClick: handleSynchronize, className: classNames({
                                    [iconLoaders.refreshIconLoader]: synchronizeMutation.isLoading
                                }) }) }), _jsx(Tooltip, { title: "Change history", children: _jsx(Button, { icon: timeIcon, onClick: handleNavigate, ...assignTestId('change-history-button') }) })] }) }), _jsx(PluginsPageBody, {})] }));
}
