// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__cf2 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 2);
  padding: calc(var(--ring-unit) * 2) 0;
}

.dataCollectionNotice__a9c {
  color: var(--ring-secondary-color);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
  margin-top: calc(var(--ring-unit) / 2);
}

.infoIcon__de1 {
  margin-left: calc(0.5 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/ai-settings-form/sections/ai-general/ai-general-section.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,+BAA+B;EAC/B,qCAAqC;AACvC;;AAEA;EACE,kCAAkC;EAClC,wCAAwC;EACxC,2CAA2C;EAC3C,sCAAsC;AACxC;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 2);\n  padding: calc(var(--ring-unit) * 2) 0;\n}\n\n.dataCollectionNotice {\n  color: var(--ring-secondary-color);\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n  margin-top: calc(var(--ring-unit) / 2);\n}\n\n.infoIcon {\n  margin-left: calc(0.5 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__cf2`,
	"dataCollectionNotice": `dataCollectionNotice__a9c`,
	"infoIcon": `infoIcon__de1`
};
export default ___CSS_LOADER_EXPORT___;
