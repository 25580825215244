// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formContainer__c75 {
  display: flex;
  gap: calc(var(--ring-unit) * 5);
}

.mainContent__e61 {
  display: flex;
  flex-direction: column;
  min-width: 722px;
}

.section__cfe {
  border-top: 1px solid var(--ring-line-color);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/ai-settings-form/ai-settings-form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".formContainer {\n  display: flex;\n  gap: calc(var(--ring-unit) * 5);\n}\n\n.mainContent {\n  display: flex;\n  flex-direction: column;\n  min-width: 722px;\n}\n\n.section {\n  border-top: 1px solid var(--ring-line-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `formContainer__c75`,
	"mainContent": `mainContent__e61`,
	"section": `section__cfe`
};
export default ___CSS_LOADER_EXPORT___;
