import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { parseISO } from 'date-fns';
import Text from '@jetbrains/ring-ui/components/text/text';
import groupIcon from '@jetbrains/icons/group';
import infoIcon from '@jetbrains/icons/info';
import Icon from '@jetbrains/ring-ui/components/icon';
import styles from '@app/pages/dashboard/active-users.css';
import { useActiveUsersWidgetStatistics } from '@common/queries/dashboard';
import { TitleSize, Widget, WidgetSize, WidgetVariant } from '@common/widgets/widget/widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { IntervalStatisticsWidgetCounter } from '@common/widgets/statistics-widget/statistics-widget-counter';
import { StatisticsWidgetBarChart } from '@common/widgets/statistics-widget/statistics-widget-bar-chart';
import NavigationLink from '@common/navigation/navigation-link';
import { formatPlural } from '@common/format-plural';
import { useUnassignedUsersCount } from '@common/queries/users';
import { PopupMessage } from '@common/popup-message/popup-message';
export function ActiveUsers() {
    var _a;
    const { data, isLoading } = useActiveUsersWidgetStatistics();
    const { data: usersCount, isLoading: userCountIsLoading } = useUnassignedUsersCount();
    const [activeInterval, setActiveInterval] = useState();
    const chartIntervals = useMemo(() => ((data === null || data === void 0 ? void 0 : data.perDays) || [])
        .map(it => ({
        from: parseISO(it.date),
        to: parseISO(it.date),
        total: it.activeUsersCount
    }))
        .sort((a, b) => {
        if (a.from.getTime() === b.from.getTime()) {
            return 0;
        }
        return a.from.getTime() < b.from.getTime() ? -1 : 1;
    }), [data]);
    const hasActiveUsers = useMemo(() => !!(data === null || data === void 0 ? void 0 : data.total) && (data === null || data === void 0 ? void 0 : data.total) > 0, [data]);
    useEffect(() => {
        if (!activeInterval && chartIntervals.length) {
            setActiveInterval(chartIntervals[chartIntervals.length - 1]);
        }
    }, [activeInterval, chartIntervals]);
    return (_jsxs(Widget, { title: "Active Users", loading: isLoading || userCountIsLoading, widgetSize: WidgetSize.Medium, titleSize: TitleSize.LARGE, variant: WidgetVariant.Filled, icon: groupIcon, ...assignTestId('active-users'), children: [!hasActiveUsers && _jsx(Text, { info: true, children: "No active users this month" }), hasActiveUsers && activeInterval && (_jsxs(_Fragment, { children: [usersCount && usersCount.total > 0 && (_jsx("div", { className: styles.usersContainer, children: _jsxs(Text, { className: styles.warningText, children: [formatPlural(usersCount.total, {
                                    one: 'One user',
                                    multiple: `${usersCount.total} users`
                                }), ' ', "without any assigned profiles", _jsx(PopupMessage, { anchor: _jsx(Icon, { glyph: infoIcon }), children: _jsxs("div", { className: styles.popupContainer, children: [_jsx(Text, { children: "The users are not able to use any product. Please assign a profile to those users." }), _jsxs("div", { className: styles.links, children: [_jsx(NavigationLink, { href: "/users?profileId=no-profile", children: "To users" }), _jsx(NavigationLink, { href: "/profiles", children: "To profiles" })] })] }) })] }) })), _jsx(StatisticsWidgetBarChart, { intervals: chartIntervals, activeInterval: activeInterval, onActivateInterval: setActiveInterval }), _jsx(IntervalStatisticsWidgetCounter, { activeInterval: activeInterval, icon: groupIcon, count: (_a = activeInterval === null || activeInterval === void 0 ? void 0 : activeInterval.total) !== null && _a !== void 0 ? _a : 0, measure: "Active users" })] }))] }));
}
