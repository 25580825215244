import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { changeAiSettings, deleteProvider, disableAi, enableAi, getAiState, getProviderInfo } from '@api/ai-enterprise';
import { formatApiError } from '@api/errors';
import queryClient from '@app/common/queries/query-client';
import { getMutationAlertMessages, getQueryAlertMessages } from '@app/common/queries/formatters/alerts';
import auth from '@app/common/auth/auth';
import { invalidateAllQueries } from '@common/queries/functions';
import { DEFAULT_POLLING_INTERVAL } from '@common/queries/constants';
export const keys = {
    all: ['ai'],
    status: ['ai', 'ai-enabled'],
    provider: (provider) => ['ai', { provider }]
};
const aiConstituentQuery = {
    queryKey: keys.status,
    queryFn: getAiState
};
export function useAiConstituent() {
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    return useQuery({
        ...aiConstituentQuery,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch AI Enterprise status')),
        refetchInterval: state => {
            return (state === null || state === void 0 ? void 0 : state.status) === 'enabled' && state.data.activeUsersCount === 0
                ? DEFAULT_POLLING_INTERVAL
                : false;
        },
        enabled: !offlineModeEnabled
    });
}
export function useAiEnableMutation(onSuccess) {
    return useMutation({
        mutationFn: enableAi,
        onSuccess: async (newAiEnabledState) => {
            queryClient.setQueryData(keys.status, newAiEnabledState);
            await invalidateAllQueries();
            onSuccess(newAiEnabledState);
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to enable AI Enterprise'), () => 'AI Enterprise enabled')
    });
}
export function useAiDisableMutation(onSuccess) {
    return useMutation({
        mutationFn: disableAi,
        onSuccess: async (newAiEnabledState) => {
            queryClient.setQueryData(keys.status, newAiEnabledState);
            await invalidateAllQueries();
            onSuccess();
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to disable AI Enterprise'), () => 'AI Enterprise disabled')
    });
}
export function fetchAiEnabled() {
    return queryClient.fetchQuery({
        queryKey: keys.status,
        queryFn: getAiState,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch AI Enterprise status'))
    });
}
export function ensureAiConstituentQueryData() {
    return queryClient.ensureQueryData({
        ...aiConstituentQuery,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch AI Enterprise status'))
    });
}
export function useUpdateAiConfigurationMutation(newAiConfiguration, migrations) {
    return useMutation({
        mutationFn: async () => {
            const currentState = await queryClient.ensureQueryData({ ...aiConstituentQuery });
            if (currentState.status !== 'enabled') {
                throw new Error('AI Enterprise is not enabled');
            }
            const currentProviders = currentState.data.provider;
            const resultProviders = Object.values(newAiConfiguration.provider)
                .filter(Boolean)
                .reduce((acc, value) => ({ ...acc, [value.id]: value }), {});
            // we should create only new providers or update exist ones to make migration possible
            // deletion will be performed by migrations
            const updatedUpdateRequest = {
                ...newAiConfiguration,
                provider: { ...currentProviders, ...resultProviders }
            };
            await changeAiSettings(updatedUpdateRequest);
            await Promise.all(migrations.map(migration => deleteProvider(migration.provider, migration.type === 'migrate' ? migration.targetProvider : undefined)));
            return queryClient.fetchQuery({ ...aiConstituentQuery });
        },
        meta: getMutationAlertMessages(error => formatApiError(error, 'Failed to update AI configuration'), () => 'AI Enterprise settings changed')
    });
}
export function useProviderInfos(providers) {
    return useQueries({
        queries: providers.map(provider => ({
            queryFn: () => getProviderInfo(provider),
            queryKey: keys.provider(provider),
            meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to fetch provider data')),
            select: (data) => ({
                provider,
                activeIdeProfilesCount: data.activeIdeProfilesCount
            })
        }))
    });
}
