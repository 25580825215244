import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { IdeProvisionerWidget } from '@modules/ide-provisioner/ide-provisioner-constituent';
import { AiEnterpriseWidget } from '@modules/ai/ai-constituent';
import { ToolboxAppWidget } from '@modules/applications/widgets/toolbox-app-widget/toolbox-app-widget';
import { CodeWithMeSessionsWidget, CodeWithMeWidget } from '@modules/code-with-me/code-with-me-constituent';
import { LicenseVaultWidget } from '@modules/license-vault/license-vault-constituent';
import { isConstituentAllowedToUseByLicense } from '@modules/constituents/is-constituent-allowed-to-use-by-license';
import { AuthenticationWidget } from '@modules/authentication/widgets/authentication-widget';
import { useTbeLicense } from '@common/queries/tbe-license';
import { useConstituentState } from '@common/queries/constituents-common';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useAuthentication } from '@common/queries/authentication';
import auth from '../../common/auth/auth';
import { ActiveUsers } from './active-users';
import styles from './dashboard-page.css';
export function DashboardPage() {
    const { data } = useTbeLicense();
    const { data: cwmState } = useConstituentState('cwm');
    const { data: authentication } = useAuthentication();
    const offlineModeEnabled = !!auth.getFeatures().offlineModeEnabled;
    const licenseType = data === null || data === void 0 ? void 0 : data.plan;
    const lvLicense = data === null || data === void 0 ? void 0 : data.constituents.find(c => c.id === 'lv');
    const aiLicense = data === null || data === void 0 ? void 0 : data.constituents.find(c => c.id === 'ai');
    const cwmLicense = data === null || data === void 0 ? void 0 : data.constituents.find(c => c.id === 'cwm');
    const idepLicense = data === null || data === void 0 ? void 0 : data.constituents.find(c => c.id === 'idep');
    const anyConstituentAvailable = lvLicense || cwmLicense || idepLicense || (aiLicense && !offlineModeEnabled);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.widgetsContainer, ...assignTestId('dashboard-widgets', 'organisation-widgets'), children: [_jsx(ToolboxAppWidget, {}), authentication && _jsx(AuthenticationWidget, { authentication: authentication }), auth.getFeatures().enableUserActivityAnalytics && _jsx(ActiveUsers, {}), cwmLicense && cwmState === 'SERVER_ENABLED' && _jsx(CodeWithMeSessionsWidget, {})] }), anyConstituentAvailable && licenseType && (_jsxs("div", { className: styles.widgetsContainer, ...assignTestId('product-widgets'), children: [idepLicense && isConstituentWidgetCanBeShown(idepLicense) && (_jsx(IdeProvisionerWidget, { constituentLicense: idepLicense, licenseType: licenseType })), aiLicense && isConstituentWidgetCanBeShown(aiLicense) && !offlineModeEnabled && (_jsx(AiEnterpriseWidget, { constituentLicense: aiLicense, licenseType: licenseType })), cwmLicense && isConstituentWidgetCanBeShown(cwmLicense) && (_jsx(CodeWithMeWidget, { constituentLicense: cwmLicense, licenseType: licenseType })), lvLicense && isConstituentWidgetCanBeShown(lvLicense) && (_jsx(LicenseVaultWidget, { constituentLicense: lvLicense, licenseType: licenseType }))] }))] }));
}
function isConstituentWidgetCanBeShown(constituentLicense) {
    if (!constituentLicense) {
        return false;
    }
    if (isConstituentAllowedToUseByLicense(constituentLicense)) {
        return true;
    }
    switch (constituentLicense.trial.state) {
        case 'ENDED':
        case 'AVAILABLE':
            return true;
        default:
            return false;
    }
}
