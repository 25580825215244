import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@jetbrains/ring-ui/components/button/button';
import { ConstituentBlock } from '@modules/constituents/constituent-block';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { LicenseVaultLogo } from '../../../common/license-vault-logo/license-vault-logo';
import styles from './license-vault-disabled.css';
export function LicenseVaultDisabled(props) {
    const { className, onEnabledClick, trialData } = props;
    return (_jsxs(ConstituentBlock, { title: "License Vault", state: "disabled", titleLogo: _jsx(LicenseVaultLogo, {}), className: className, trialData: trialData, ...assignTestId('lv-block'), children: [_jsx("p", { children: "License Vault supports management of JetBrains licenses in your organization" }), _jsx(Button, { onClick: onEnabledClick, className: styles.button, "data-test": "enable-button", children: "Enable..." })] }));
}
