import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import Input from '@jetbrains/ring-ui/components/input/input';
import { assignTestId } from '@common/infra/tests/assign-test-id';
export function UserEmailField(props) {
    var _a;
    const { className, disabled } = props;
    const { watch, setValue, getFieldState } = useFormContext();
    const handleEmailChange = (email) => {
        setValue('email', email, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    };
    return (_jsx(Input, { label: "Email", name: "email", autoComplete: "off", value: watch('email'), onChange: e => handleEmailChange(e.target.value), disabled: disabled, className: className, error: (_a = getFieldState('email').error) === null || _a === void 0 ? void 0 : _a.message, ...assignTestId('form-email') }));
}
