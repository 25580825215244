// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__abc {
  width: calc(75 * var(--ring-unit));
}

.message__c05 + .defaultProviderSelector__dd3,
.message__c05 + .migrationSelector__b99 {
  margin-top: calc(var(--ring-unit) * 3);
}

.defaultProviderSelector__dd3 + .migrationSelector__b99 {
  margin-top: calc(var(--ring-unit) * 3);
}

.migrationSelector__b99 + .migrationSelector__b99 {
  margin-top: calc(var(--ring-unit) * 2);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/ai-settings-form/migration-dialog/migration-dialog.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;;EAEE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".container {\n  width: calc(75 * var(--ring-unit));\n}\n\n.message + .defaultProviderSelector,\n.message + .migrationSelector {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.defaultProviderSelector + .migrationSelector {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n\n.migrationSelector + .migrationSelector {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__abc`,
	"message": `message__c05`,
	"defaultProviderSelector": `defaultProviderSelector__dd3`,
	"migrationSelector": `migrationSelector__b99`
};
export default ___CSS_LOADER_EXPORT___;
