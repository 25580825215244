// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warningText__e22 {
  color: var(--ring-warning-color);
  text-align: justify;
}

.warningIcon__d01 {
  margin-right: var(--ring-unit);
}

.infoText__d97 {
  text-align: justify;
}

.formContainer__a2c {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ring-unit) * 1.5);
}

.errorMessage__d6d {
  margin-top: var(--ring-unit);
  color: var(--ring-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/profiles/profile-details/profile-ide-provisioner/settings/vm-options/profiles-vm-options-form.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,4BAA4B;EAC5B,8BAA8B;AAChC","sourcesContent":[".warningText {\n  color: var(--ring-warning-color);\n  text-align: justify;\n}\n\n.warningIcon {\n  margin-right: var(--ring-unit);\n}\n\n.infoText {\n  text-align: justify;\n}\n\n.formContainer {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--ring-unit) * 1.5);\n}\n\n.errorMessage {\n  margin-top: var(--ring-unit);\n  color: var(--ring-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningText": `warningText__e22`,
	"warningIcon": `warningIcon__d01`,
	"infoText": `infoText__d97`,
	"formContainer": `formContainer__a2c`,
	"errorMessage": `errorMessage__d6d`
};
export default ___CSS_LOADER_EXPORT___;
