import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import Button from '@jetbrains/ring-ui/components/button/button';
import { ToolboxLogo } from '@jetbrains/logos/react';
import { DownloadTbeToolboxApp, useMachineConfigDownloadButtonProps } from '@modules/machine-config';
import { useCompanyName } from '@common/hooks/use-company-name';
import { WidgetVariant, Widget, WidgetSize, TitleSize } from '@common/widgets/widget/widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './toolbox-app-widget.css';
export function ToolboxAppWidget() {
    const [showDownloadToolbox, setShowDownloadToolbox] = useState(false);
    const { companyName } = useCompanyName();
    return (_jsx(Widget, { variant: WidgetVariant.Filled, widgetSize: WidgetSize.Medium, titleSize: TitleSize.LARGE, title: "Toolbox App", icon: _jsx(ToolboxLogo, {}), ...assignTestId('toolbox-app-widget'), children: _jsxs("div", { className: styles.container, children: [_jsxs(Text, { className: styles.textContainer, children: ["Join ", companyName, " in the Toolbox App to gain access to approved applications."] }), _jsx(Button, { className: styles.button, loader: !companyName, "data-test": "join-organization-button", onClickCapture: () => setShowDownloadToolbox(true), autoFocus: true, ...useMachineConfigDownloadButtonProps(`Join ${companyName}`) }), showDownloadToolbox && (_jsxs("div", { className: styles.downloadToolboxContainer, children: [_jsxs(Text, { children: ["Did that not work? ", _jsx("br", {}), " Ensure the Toolbox App is installed"] }), _jsx(DownloadTbeToolboxApp, { primary: true })] }))] }) }));
}
