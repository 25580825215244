import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Content } from '@jetbrains/ring-ui/components/island/island';
import { FormProvider, useForm } from 'react-hook-form';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { useDialogCancelConfirmation } from '@app/common/hooks/use-dialog-cancel-confirmation';
import { ToolsFilter } from '@modules/applications/tools-filter';
import { Dialog } from '@common/dialog/dialog';
import { getApplications } from '@common/store/profiles/selectors';
import { useEvaluatedSettingFilters } from '@common/queries/profile-evaluations';
import { tAutoInstalledPluginsSchema } from '../../schemas/auto-installed-plugins-schema';
import { getEmptyAutoInstalledPluginFormValues } from '../../schemas/ctors';
import DialogHeader from '../../common/dialog-header';
import { useUserPluginRestrictedMode } from '../../hooks/user-restricted-modes';
import ContentField from './fields/content-field';
import styles from './auto-installed-plugins-dialog-form.css';
function AutoInstalledPluginsDialogForm({ initialFormValues, onReject, onSubmit }) {
    var _a, _b, _c;
    const { id: profileId } = useParams();
    const { isRestricted, restrictedPlugins } = useUserPluginRestrictedMode();
    const form = useForm({
        defaultValues: (_a = mapAutoinstalledToType(initialFormValues)) !== null && _a !== void 0 ? _a : getEmptyAutoInstalledPluginFormValues(),
        resolver: zodResolver(tAutoInstalledPluginsSchema(isRestricted, restrictedPlugins))
    });
    const { handleSubmit, formState: { isSubmitting, isDirty, isValid, errors }, watch, setValue } = form;
    const watchFilters = watch('filters');
    const setFilters = (filters) => {
        setValue('filters', filters, {
            shouldDirty: true,
            shouldValidate: true
        });
    };
    const applications = useSelector(getApplications);
    const { isFetching: isFilterToolsLoading, data: filterTools } = useEvaluatedSettingFilters(applications.rules, watchFilters);
    const canSubmit = isDirty && isValid && !isFilterToolsLoading;
    const { onCloseAttempt, cancelButtonRef } = useDialogCancelConfirmation(onReject, isDirty);
    const submit = (values) => {
        onSubmit({ ...values, tools: filterTools !== null && filterTools !== void 0 ? filterTools : [] });
    };
    if (!profileId) {
        return _jsx(Navigate, { to: "/profiles" });
    }
    return (_jsxs(Dialog, { label: "Configure Auto-Installed Plugins", show: true, autoFocusFirst: false, onCloseAttempt: onCloseAttempt, size: "wide", children: [_jsx(DialogHeader, { children: "Configure Auto-Installed Plugins" }), _jsx(Content, { children: _jsx(FormProvider, { ...form, children: _jsxs("form", { onSubmit: handleSubmit(submit), className: styles.formContainer, children: [_jsx(ToolsFilter, { filters: watchFilters, onFiltersChange: setFilters, target: "settings", disabledWithMessage: isRestricted ? 'Filter modification is disallowed in Restricted Mode' : undefined, profileId: profileId, filterTools: filterTools !== null && filterTools !== void 0 ? filterTools : [], isFilterToolsLoading: isFilterToolsLoading }), _jsx(ContentField, {}), ((_b = errors.filters) === null || _b === void 0 ? void 0 : _b.message) && (_jsx("div", { className: styles.errorMessage, children: ((_c = errors.filters) === null || _c === void 0 ? void 0 : _c.message) && _jsx("p", { children: errors.filters.message }) }))] }) }) }), _jsxs(Panel, { children: [_jsx(Button, { primary: true, onClick: handleSubmit(submit), type: "submit", loader: isSubmitting, disabled: !canSubmit, children: "Save" }), _jsx(Button, { ref: cancelButtonRef, onClick: onReject, children: "Cancel" })] })] }));
}
export default AutoInstalledPluginsDialogForm;
function mapAutoinstalledToType(initialValue) {
    if (!initialValue) {
        return undefined;
    }
    const contentWithTypes = initialValue.content.map(values => {
        return {
            ...values,
            type: !isNil(values.version) ? 'version' : 'channel'
        };
    });
    return { ...initialValue, content: contentWithTypes };
}
