// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__ad0 {
  padding-top: calc(var(--ring-unit) * 3);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/ai-settings-form/sections/ai-form-actions/ai-form-actions.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;AACzC","sourcesContent":[".wrapper {\n  padding-top: calc(var(--ring-unit) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__ad0`
};
export default ___CSS_LOADER_EXPORT___;
