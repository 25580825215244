import { useEffect, useState } from 'react';
import { noop } from 'lodash';
export function useIsFocused(ref, initiallyFocused) {
    useEffect(() => {
        const refCurrent = ref.current;
        if (refCurrent) {
            const handleEvent = (e) => {
                if (e.target instanceof Node && refCurrent.contains(e.target)) {
                    setFocused(true);
                }
                else {
                    setFocused(false);
                }
            };
            document.addEventListener('focus', handleEvent, { capture: true });
            document.addEventListener('click', handleEvent, { capture: true });
            return () => {
                document.removeEventListener('focus', handleEvent, { capture: true });
                document.removeEventListener('click', handleEvent, { capture: true });
            };
        }
        return noop;
    }, [ref]);
    const [focused, setFocused] = useState(initiallyFocused);
    return [focused, setFocused];
}
