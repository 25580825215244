import { useQuery } from '@tanstack/react-query';
import { fetchBundledPlugins } from '@api/marketplace';
import { formatApiError } from '@api/errors';
import { getQueryAlertMessages } from '../formatters/alerts';
import { toPluginModel } from './plugins';
const keys = {
    plugins: ['bundled-plugins']
};
export function useBundledPlugins() {
    return useQuery({
        queryKey: keys.plugins,
        queryFn: () => fetchBundledPlugins().then(response => response.map(plugin => toPluginModel(plugin.xmlId, 'MARKETPLACE', plugin.name))),
        placeholderData: [],
        staleTime: Infinity,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load bundled plugins'))
    });
}
