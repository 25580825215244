import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Badge from '@jetbrains/ring-ui/components/badge/badge';
import classNames from 'classnames';
import Icon from '@jetbrains/ring-ui/components/icon';
import info from '@jetbrains/icons/info';
import { PopupMessage } from '@common/popup-message/popup-message';
import styles from './badges.css';
export function TrialEndedBadge(props) {
    const { tooltipContent, tooltipTitle } = props;
    const badge = (_jsxs(Badge, { className: classNames(styles.badge, styles.error), children: ["Trial ended ", tooltipContent && _jsx(Icon, { className: styles.icon, glyph: info })] }));
    if (!tooltipContent) {
        return badge;
    }
    return (_jsx(PopupMessage, { title: tooltipTitle, anchor: badge, children: tooltipContent }));
}
