import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { format } from 'date-fns';
import Icon, { Color } from '@jetbrains/ring-ui/components/icon/icon';
import newWindowIcon from '@jetbrains/icons/new-window';
import { IconSize } from '@jetbrains/ring-ui/components/button/button';
import { ExternalLinks } from '@common/constants/constants';
import NavigationLink from '@common/navigation/navigation-link';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import styles from './trial-notification-wrapper.css';
export function TrialNotificationWrapper(props) {
    const { trial, children } = props;
    return (_jsxs(_Fragment, { children: [(trial === null || trial === void 0 ? void 0 : trial.state) === 'ACTIVE' && trial.endDate && (_jsx("p", { className: styles.trialEndMessage, ...assignTestId('trial-end-date'), children: formatTrialEndDate(trial.endDate) })), _jsx("div", { className: styles.widgetContentWrapper, children: children }), (trial === null || trial === void 0 ? void 0 : trial.state) === 'AVAILABLE' && (_jsx("div", { className: styles.trialStartMessage, children: _jsxs("p", { children: [_jsx(NavigationLink, { href: ExternalLinks.JB_CONTACT_SALES_FORM, target: "_blank", children: "Request a trial" }), ' ', _jsx(Icon, { glyph: newWindowIcon, size: IconSize.Size16, color: Color.BLUE })] }) }))] }));
}
function formatTrialEndDate(trialEnd) {
    const trialEndText = format(trialEnd, 'MMM dd, yyyy');
    return `Your trial ends on ${trialEndText}`;
}
