import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import TagsList from '@jetbrains/ring-ui/components/tags-list/tags-list';
import Text from '@jetbrains/ring-ui/components/text/text';
import Dropdown from '@jetbrains/ring-ui/components/dropdown/dropdown';
import Popup from '@jetbrains/ring-ui/components/popup/popup';
import { Directions } from '@jetbrains/ring-ui/components/popup/popup.consts';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { getBuildsOptions } from '../../../../common/build-filters';
import { getBuildsListOptions } from './get-builds-list-options';
import { NestedCheckbox } from './nested-checkbox';
import { getUpdatedBuilds } from './get-updated-builds';
import styles from './build-field.css';
function BuildField({ allProducts }) {
    const { setValue, watch } = useFormContext();
    const products = watch('products');
    const builds = watch('builds');
    const buildOptions = useMemo(() => getBuildsOptions(allProducts, products !== null && products !== void 0 ? products : [], build => !build.match(/^\d{3}\./)).map(option => ({
        ...option,
        id: option.build,
        selected: (builds || []).some(it => it === option.build)
    })), [allProducts, products, builds]);
    const dataListOptions = useMemo(() => {
        return getBuildsListOptions(buildOptions).map(addSelectedFlag);
        function addSelectedFlag({ items, ...rest }) {
            return {
                items: items.map(addSelectedFlag),
                selected: (builds || []).some(it => it === rest.build),
                ...rest
            };
        }
    }, [buildOptions, builds]);
    const handleSelection = useCallback((buildKey) => {
        setValue('builds', getUpdatedBuilds(buildKey, builds, dataListOptions), { shouldDirty: true });
    }, [builds, dataListOptions, setValue]);
    const onRemoveTag = useCallback((tag) => (e) => {
        e.stopPropagation();
        if (typeof tag.key === 'string') {
            handleSelection(tag.key);
        }
    }, [handleSelection]);
    const dropdownAnchor = useCallback((anchorProps) => (_jsx("div", { ...anchorProps, className: styles.tagsContainer, children: !builds || !builds.length ? (_jsx(Text, { info: true, className: styles.placeholder, children: "All" })) : (_jsx(TagsList, { id: "buildsField", tags: builds.map(build => ({ key: build, label: build })), handleRemove: onRemoveTag })) })), [builds, onRemoveTag]);
    return (_jsxs("div", { ...assignTestId('application-filter-build'), children: [_jsx("p", { className: styles.label, children: "Build" }), _jsx(Dropdown, { anchor: dropdownAnchor, className: styles.dropdownContainer, children: _jsx(Popup, { className: styles.popupContainer, directions: [Directions.BOTTOM_CENTER], children: dataListOptions.map(build => (_jsx(NestedCheckbox, { buildOption: build, onChange: handleSelection, hasCheckedParent: false }))) }) })] }));
}
export default BuildField;
