// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialogContent__ba6 {
  padding-bottom: calc(2 * var(--ring-unit));
}
`, "",{"version":3,"sources":["webpack://./src/modules/applications/tools-filter/filter-dialog/filter-form.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;AAC5C","sourcesContent":[".dialogContent {\n  padding-bottom: calc(2 * var(--ring-unit));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContent": `dialogContent__ba6`
};
export default ___CSS_LOADER_EXPORT___;
