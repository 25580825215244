// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__e72 {
  width: calc(var(--ring-unit) * 82);

  .info__a97 {
    color: var(--ring-secondary-color);
    font-size: var(--ring-font-size-smaller);
    line-height: var(--ring-line-height-lowest);
  }

  .filter__cf5 + .filter__cf5 {
    margin-top: var(--ring-unit);
  }

  .info__a97 {
    margin-top: var(--ring-unit);
  }

  .allowedTools__a2a {
    margin-top: calc(var(--ring-unit) * 2);
  }

  .addFilterBtn__cef {
    margin-top: calc(var(--ring-unit) * 3);
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/applications/tools-filter/tools-filter.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;;EAElC;IACE,kCAAkC;IAClC,wCAAwC;IACxC,2CAA2C;EAC7C;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,sCAAsC;EACxC;;EAEA;IACE,sCAAsC;EACxC;AACF","sourcesContent":[".wrapper {\n  width: calc(var(--ring-unit) * 82);\n\n  .info {\n    color: var(--ring-secondary-color);\n    font-size: var(--ring-font-size-smaller);\n    line-height: var(--ring-line-height-lowest);\n  }\n\n  .filter + .filter {\n    margin-top: var(--ring-unit);\n  }\n\n  .info {\n    margin-top: var(--ring-unit);\n  }\n\n  .allowedTools {\n    margin-top: calc(var(--ring-unit) * 2);\n  }\n\n  .addFilterBtn {\n    margin-top: calc(var(--ring-unit) * 3);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__e72`,
	"info": `info__a97`,
	"filter": `filter__cf5`,
	"allowedTools": `allowedTools__a2a`,
	"addFilterBtn": `addFilterBtn__cef`
};
export default ___CSS_LOADER_EXPORT___;
