import { useQuery } from '@tanstack/react-query';
import { fetchPluginsByIds, fetchPluginsByPluginName } from '@api/marketplace';
import { formatApiError } from '@api/errors';
import { getQueryAlertMessages } from '../formatters/alerts';
const keys = {
    all: ['marketplace-plugins'],
    list: (xmlIds) => ['marketplace-plugins', 'list', xmlIds],
    filtered: (filter) => ['marketplace-plugins', 'filtered', filter]
};
export function useOriginalMarketplacePlugins(xmlIds, enabled) {
    return useQuery({
        queryKey: keys.list(xmlIds),
        queryFn: () => fetchPluginsByIds(xmlIds),
        enabled,
        placeholderData: [],
        staleTime: Infinity,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load marketplace plugins'))
    });
}
export function useOriginalFilteredMarketplacePlugins(filter, enabled = true) {
    return useQuery({
        queryKey: keys.filtered(filter),
        queryFn: () => fetchPluginsByPluginName(filter),
        enabled,
        placeholderData: [],
        staleTime: Infinity,
        meta: getQueryAlertMessages(error => formatApiError(error, 'Failed to load filtered marketplace plugins'))
    });
}
