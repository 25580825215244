import { union } from 'lodash';
import { getBuildsOptions } from '../common/build-filters';
export const getBuildsGroups = (evaluatedApplicationData) => {
    const buildsWildcardGroups = getBuildsOptions([
        {
            id: evaluatedApplicationData === null || evaluatedApplicationData === void 0 ? void 0 : evaluatedApplicationData.product,
            name: evaluatedApplicationData === null || evaluatedApplicationData === void 0 ? void 0 : evaluatedApplicationData.productName,
            builds: union(evaluatedApplicationData === null || evaluatedApplicationData === void 0 ? void 0 : evaluatedApplicationData.exclude, evaluatedApplicationData === null || evaluatedApplicationData === void 0 ? void 0 : evaluatedApplicationData.include).map(({ build, version }) => ({ id: build, name: version }))
        }
    ], []);
    const buildsData = buildsWildcardGroups.map(item => {
        const allBuilds = union(evaluatedApplicationData.include, evaluatedApplicationData.exclude).filter(matchesCurrentKey(item.build));
        const excludedBuilds = evaluatedApplicationData.exclude
            .filter(matchesCurrentKey(item.build))
            .map(it => it.build);
        return {
            id: item.build,
            product: evaluatedApplicationData.product,
            label: item.label || item.build,
            notifications: evaluatedApplicationData.notifications,
            children: allBuilds.map(({ build, version }) => {
                return {
                    id: build,
                    product: evaluatedApplicationData.product,
                    version,
                    label: `${evaluatedApplicationData.product} ${version}`,
                    notifications: (evaluatedApplicationData.notifications || []).filter(({ applicable }) => applicable.includes(build)),
                    isAutoinstalled: build === evaluatedApplicationData.autoinstalled,
                    isExcluded: excludedBuilds.includes(build)
                };
            })
        };
    });
    return buildsData;
};
function matchesCurrentKey(buildsGroupKey) {
    return (build) => {
        return build.build.startsWith(buildsGroupKey.split('.')[0]);
    };
}
