import { jsx as _jsx } from "react/jsx-runtime";
import { useController, useFormContext } from 'react-hook-form';
import { ToolboxSettingField } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/toolbox-setting-field';
import { assignTestId } from '@common/infra/tests/assign-test-id';
const DEFAULT_SEND_ANONYMOUS_USER_STATISTICS_TOOLBOX_VALUE = {
    value: false,
    forced: false
};
export function SendAnonymousUserStatisticsSettingField() {
    var _a;
    const { control } = useFormContext();
    const { field: { value: sendAnonymousUserStatistics, onChange: onSendAnonymousUserStatisticsChange } } = useController({
        control,
        name: 'content.sendAnonymousUserStatistics'
    });
    return (_jsx(ToolboxSettingField, { label: "Send anonymous user statistics", value: sendAnonymousUserStatistics === null || sendAnonymousUserStatistics === void 0 ? void 0 : sendAnonymousUserStatistics.value, forced: (_a = sendAnonymousUserStatistics === null || sendAnonymousUserStatistics === void 0 ? void 0 : sendAnonymousUserStatistics.forced) !== null && _a !== void 0 ? _a : null, onChange: newValue => {
            onSendAnonymousUserStatisticsChange({
                ...DEFAULT_SEND_ANONYMOUS_USER_STATISTICS_TOOLBOX_VALUE,
                ...sendAnonymousUserStatistics,
                value: newValue
            });
        }, onForcedChange: newForced => {
            if (newForced === null) {
                onSendAnonymousUserStatisticsChange(null);
            }
            onSendAnonymousUserStatisticsChange({
                ...DEFAULT_SEND_ANONYMOUS_USER_STATISTICS_TOOLBOX_VALUE,
                ...sendAnonymousUserStatistics,
                forced: newForced
            });
        }, ...assignTestId('send-anonymous-user-statistics-toolbox-setting-field') }));
}
