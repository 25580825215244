import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { H2 } from '@jetbrains/ring-ui/components/heading/heading';
import cn from 'classnames';
import Select from '@jetbrains/ring-ui/components/select/select';
import { isNil } from 'lodash';
import { JetbrainsAiProviderForm, OpenAiProviderForm } from '@modules/ai/common/forms';
import { AI_PROVIDERS, DEFAULT_PROVIDER_VALUES } from '@modules/ai/common/constants';
import { GoogleAiProviderForm } from '@modules/ai/common/forms/google-ai-provider-form/google-ai-provider-form';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { ProviderFormBase } from '../../common';
import styles from './ai-configuration-section.css';
export function AiConfigurationSection(props) {
    var _a, _b, _c;
    const { form, availableProviders, className } = props;
    const { setValue, watch, formState } = form;
    const defaultProvider = watch('defaultProvider');
    const providerFormValues = watch('provider');
    const providerOptions = availableProviders
        .filter(provider => isNil(providerFormValues[provider]))
        .map(provider => AI_PROVIDERS[provider]);
    const handleSelection = (selectedProvider) => {
        if (selectedProvider) {
            setValue(`provider.${selectedProvider}`, DEFAULT_PROVIDER_VALUES[selectedProvider], {
                shouldDirty: true,
                shouldValidate: true
            });
        }
    };
    const handleDeleteProvider = (provider) => {
        setValue(`provider.${provider}`, undefined, { shouldDirty: true, shouldValidate: true });
    };
    const handleDefaultProviderChange = (provider) => {
        setValue('defaultProvider', provider, { shouldDirty: true, shouldValidate: true });
    };
    const isJetbrainsFormDirty = !!((_a = formState.dirtyFields.provider) === null || _a === void 0 ? void 0 : _a.jetbrains);
    const isGoogleFormDirty = !!((_b = formState.dirtyFields.provider) === null || _b === void 0 ? void 0 : _b.google);
    const isOpenAiFormDirty = !!((_c = formState.dirtyFields.provider) === null || _c === void 0 ? void 0 : _c.openai);
    return (_jsxs("section", { className: cn(styles.wrapper, className), ...assignTestId('ai-configuration-section'), children: [_jsx(H2, { className: styles.title, children: "AI Providers" }), !isNil(providerFormValues.jetbrains) && (_jsx(ProviderFormBase, { isDefaultProvider: defaultProvider === 'jetbrains', label: "JetBrains AI", onDefaultProviderChange: () => handleDefaultProviderChange('jetbrains'), onDelete: () => handleDeleteProvider('jetbrains'), isDirty: isJetbrainsFormDirty, className: styles.section, ...assignTestId('jetbrains'), children: _jsx(JetbrainsAiProviderForm, { form: form }) })), !isNil(providerFormValues.openai) && (_jsx(ProviderFormBase, { isDefaultProvider: defaultProvider === 'openai', label: "Open AI", onDefaultProviderChange: () => handleDefaultProviderChange('openai'), onDelete: () => handleDeleteProvider('openai'), isDirty: isOpenAiFormDirty, className: styles.section, ...assignTestId('openai'), children: _jsx(OpenAiProviderForm, { form: form }) })), !isNil(providerFormValues.google) && (_jsx(ProviderFormBase, { isDefaultProvider: defaultProvider === 'google', label: "Google Vertex AI", onDefaultProviderChange: () => handleDefaultProviderChange('google'), onDelete: () => handleDeleteProvider('google'), isDirty: isGoogleFormDirty, className: styles.section, ...assignTestId('google'), children: _jsx(GoogleAiProviderForm, { form: form }) })), providerOptions.length !== 0 && (_jsx(Select, { label: "Add AI provider", selected: { key: 'none', label: 'Add AI provider' }, data: providerOptions, onSelect: val => handleSelection(val === null || val === void 0 ? void 0 : val.key), className: styles.addProviderBtn, ...assignTestId('provider-selector') }))] }));
}
