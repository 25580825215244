import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Input, { Size as InputSize } from '@jetbrains/ring-ui/components/input/input';
import { Required } from '@common/form/important-asterisk';
import RingFieldsList from '@common/form/ring-fields-list';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { GoogleAiModelsTable } from './google-ai-models-table';
const TOKEN_EXISTENCE_INDICATOR = '⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱';
export function GoogleAiProviderForm({ form }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const { setValue, watch, formState: { errors, touchedFields } } = form;
    const token = watch('provider.google.token');
    return (_jsxs(RingFieldsList, { children: [_jsx(Input, { ...assignTestId('project-input'), value: watch('provider.google.project'), label: Required('Project'), onChange: val => setValue('provider.google.project', val.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                }), size: InputSize.L, error: ((_b = (_a = touchedFields.provider) === null || _a === void 0 ? void 0 : _a.google) === null || _b === void 0 ? void 0 : _b.project)
                    ? (_e = (_d = (_c = errors.provider) === null || _c === void 0 ? void 0 : _c.google) === null || _d === void 0 ? void 0 : _d.project) === null || _e === void 0 ? void 0 : _e.message
                    : undefined }), _jsx(Input, { ...assignTestId('region-input'), value: watch('provider.google.region'), label: Required('Region'), onChange: val => setValue('provider.google.region', val.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                }), size: InputSize.L, error: ((_g = (_f = touchedFields.provider) === null || _f === void 0 ? void 0 : _f.google) === null || _g === void 0 ? void 0 : _g.region)
                    ? (_k = (_j = (_h = errors.provider) === null || _h === void 0 ? void 0 : _h.google) === null || _j === void 0 ? void 0 : _j.region) === null || _k === void 0 ? void 0 : _k.message
                    : undefined }), _jsx(Input, { ...assignTestId('token-input'), value: watch('provider.google.token'), label: Required('Token'), placeholder: token === null ? TOKEN_EXISTENCE_INDICATOR : '', onChange: val => setValue('provider.google.token', val.target.value, {
                    shouldValidate: true,
                    shouldDirty: true
                }), type: "password", size: InputSize.L, error: ((_m = (_l = touchedFields.provider) === null || _l === void 0 ? void 0 : _l.google) === null || _m === void 0 ? void 0 : _m.token)
                    ? (_q = (_p = (_o = errors.provider) === null || _o === void 0 ? void 0 : _o.google) === null || _p === void 0 ? void 0 : _p.token) === null || _q === void 0 ? void 0 : _q.message
                    : undefined }), _jsx(GoogleAiModelsTable, { models: watch('provider.google.models') })] }));
}
