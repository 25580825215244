import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Toggle from '@jetbrains/ring-ui/components/toggle/toggle';
import Input from '@jetbrains/ring-ui/components/input/input';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import Text from '@jetbrains/ring-ui/components/text/text';
import { SETTINGS_UPDATE_INTERVAL_SECONDS_DEFAULT_VALUE, SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MAX, SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MIN } from '@app/pages/profiles/profile-details/profile-ide-provisioner/schemas/toolbox-settings-schema';
// eslint-disable-next-line no-restricted-imports
import HelpIcon from '@app/common/util/help-icon';
import styles from './settings-update-interval-setting-field.css';
export function SettingsUpdateIntervalSettingField() {
    var _a, _b;
    const { setValue, formState: { errors }, watch } = useFormContext();
    const settingsUpdateIntervalSeconds = watch('content.settingsUpdateIntervalSeconds.value');
    const settingsUpdateError = (_a = errors.content) === null || _a === void 0 ? void 0 : _a.settingsUpdateIntervalSeconds;
    const [isEnabled, setIsEnabled] = useState(!!settingsUpdateIntervalSeconds);
    const handleToggleChange = (e) => {
        setIsEnabled(e.target.checked);
        if (e.target.checked) {
            return setValue('content.settingsUpdateIntervalSeconds.value', SETTINGS_UPDATE_INTERVAL_SECONDS_DEFAULT_VALUE, {
                shouldDirty: true
            });
        }
        return setValue('content.settingsUpdateIntervalSeconds', undefined, {
            shouldDirty: true
        });
    };
    const handleInputChange = (e) => {
        setValue('content.settingsUpdateIntervalSeconds.value', e.target.value ? Number(e.target.value) * 60 : 0, { shouldDirty: true, shouldValidate: true });
    };
    return (_jsxs("div", { children: [_jsxs("div", { className: styles.container, "data-test": "settings-update-interval-toolbox-setting-field", children: [_jsxs("div", { className: styles.sectionContainer, children: [_jsx(Toggle, { checked: isEnabled, onChange: handleToggleChange, children: "\"Check for updates\" frequency" }), _jsx(Tooltip, { title: _jsx(Text, { children: "If this setting is not specified, profile users will receive updates in the Toolbox App based on either the default value (120 minutes) or the value specified in their other profiles." }), children: _jsx(HelpIcon, { className: styles.helpIcon }) })] }), _jsxs("div", { className: styles.sectionContainer, children: [_jsx(Input, { className: styles.input, value: settingsUpdateIntervalSeconds / 60, onChange: handleInputChange, disabled: !isEnabled, placeholder: `${SETTINGS_UPDATE_INTERVAL_SECONDS_DEFAULT_VALUE / 60}`, type: "number", min: SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MIN / 60, max: SETTINGS_UPDATE_INTERVAL_SECONDS_VALUE_MAX / 60 }), "\u00A0minutes"] })] }), settingsUpdateError && (_jsx(Text, { className: styles.error, children: (_b = settingsUpdateError.value) === null || _b === void 0 ? void 0 : _b.message }))] }));
}
