import { jsx as _jsx } from "react/jsx-runtime";
import { useController, useFormContext } from 'react-hook-form';
import { ToolboxSettingField } from '@app/pages/profiles/profile-details/profile-ide-provisioner/settings/toolbox-settings/form/toolbox-setting-field';
import { assignTestId } from '@common/infra/tests/assign-test-id';
const DEFAULT_UPDATE_TOOLS_AUTOMATICALLY_TOOLBOX_SETTING = {
    value: false,
    forced: false
};
export function UpdateToolsAutomaticallyToolboxSettingField() {
    var _a;
    const { control } = useFormContext();
    const { field: { value: updateToolsAutomatically, onChange: onUpdateToolsAutomaticallyChange } } = useController({
        control,
        name: 'content.updateToolsAutomatically'
    });
    return (_jsx(ToolboxSettingField, { label: "Update tools automatically", value: updateToolsAutomatically === null || updateToolsAutomatically === void 0 ? void 0 : updateToolsAutomatically.value, forced: (_a = updateToolsAutomatically === null || updateToolsAutomatically === void 0 ? void 0 : updateToolsAutomatically.forced) !== null && _a !== void 0 ? _a : null, onChange: newValue => onUpdateToolsAutomaticallyChange({
            ...DEFAULT_UPDATE_TOOLS_AUTOMATICALLY_TOOLBOX_SETTING,
            ...updateToolsAutomatically,
            value: newValue
        }), onForcedChange: newForced => {
            if (newForced === null) {
                onUpdateToolsAutomaticallyChange(null);
            }
            onUpdateToolsAutomaticallyChange({
                ...DEFAULT_UPDATE_TOOLS_AUTOMATICALLY_TOOLBOX_SETTING,
                ...updateToolsAutomatically,
                forced: newForced
            });
        }, ...assignTestId('update-tools-automatically-toolbox-setting-field') }));
}
