import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import successSessionsIcon from '@jetbrains/icons/ok';
import failedSessionsIcon from '@jetbrains/icons/exception';
import { isNil } from 'lodash';
import { useGetCwmWidgetStatistics } from '@common/queries/dashboard';
import { IntervalStatisticsWidgetCounter } from '@common/widgets/statistics-widget/statistics-widget-counter';
import { TitleSize, Widget, WidgetSize, WidgetVariant } from '@common/widgets/widget/widget';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import NavigationLink from '@common/navigation/navigation-link';
import { StatisticsWidgetBarChart } from '@common/widgets/statistics-widget/statistics-widget-bar-chart';
import { CodeWithMeLogo } from '../../common/code-with-me-logo/code-with-me-logo';
import styles from './code-with-me-sessions-widget.css';
const baseLink = '/dashboard/cwm-sessions';
export function CodeWithMeSessionsWidget() {
    const { data, isLoading } = useGetCwmWidgetStatistics();
    const [activeInterval, setActiveInterval] = useState();
    const totalCreatedSessions = useMemo(() => (data || []).reduce((accum, current) => accum + current.sessionsCount, 0), [data]);
    const chartIntervals = useMemo(() => (data || []).map(it => ({
        from: it.from,
        to: it.to,
        total: it.sessionsCount + it.sessionsFailureCount,
        error: it.sessionsFailureCount,
        link: `${baseLink}?from=${it.from.getTime()}&to=${it.to.getTime()}`
    })), [data]);
    const titleLink = useMemo(() => {
        return data && data.length
            ? `${baseLink}?from=${data[0].from.getTime()}&to=${data[data.length - 1].to.getTime()}`
            : baseLink;
    }, [data]);
    useEffect(() => {
        if (!activeInterval && chartIntervals.length) {
            setActiveInterval(chartIntervals[chartIntervals.length - 1]);
        }
    }, [activeInterval, chartIntervals]);
    return (_jsx(Widget, { loading: isLoading, title: _jsx(NavigationLink, { secondary: true, href: titleLink, children: "Code With Me Sessions" }), titleSize: TitleSize.LARGE, icon: _jsx(CodeWithMeLogo, {}), titleHint: `${totalCreatedSessions} sessions created in the last 2 weeks`, widgetSize: WidgetSize.Medium, variant: WidgetVariant.Filled, className: styles.wrapper, ...assignTestId('cwm-sessions'), children: (data === null || data === void 0 ? void 0 : data.length) && activeInterval && (_jsxs(_Fragment, { children: [_jsx(StatisticsWidgetBarChart, { colorScheme: "success-and-failure", intervals: chartIntervals, activeInterval: activeInterval, onActivateInterval: setActiveInterval }), _jsx("div", { className: styles.infoText, children: _jsxs(Text, { children: [_jsx("span", { className: styles.successful, children: "Successful" }), " and", ' ', _jsx("span", { className: styles.failed, children: "failed" }), " sessions for the last two weeks"] }) }), _jsx(IntervalStatisticsWidgetCounter, { icon: successSessionsIcon, count: activeInterval.total - (activeInterval.error || 0), measure: "Sessions created", activeInterval: activeInterval, className: styles.statisticsCounter }), !isNil(activeInterval.error) && activeInterval.error > 0 && (_jsx(IntervalStatisticsWidgetCounter, { icon: failedSessionsIcon, count: activeInterval.error, measure: "Sessions failed", activeInterval: activeInterval }))] })) }));
}
